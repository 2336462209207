import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";
import LoaderSmall from "../../components/loader-small";
import {createResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getProp} from "../../util/util";
import FieldRadio from "../../components/field-radio";
import FieldCheckbox from "../../components/field-checkbox";
import FieldText from "../../components/field-text";
import Button from "../../components/button";
import ConfirmQuizDialog from "../../components/dialog/confirm-quiz-dialog";
import FieldTextarea from "../../components/field-textarea";
import {Link} from "react-router-dom";
import Timer from "react-compound-timer";

class QuizView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            values: {},
            spajalices: {},
            confirmModal: false
        };
    }

    componentDidMount() {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId()
            },
            resource: Resources.CourseQuizStart
        }));
    }

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    submit = () => {
        this.setState({
            confirmModal: false
        }, () => {
            this.props.dispatch(createResource({
                user: LocalStorage.get("user"),
                params: {
                    QuizResultID: getProp(this.props.resource, "data.QuizResultID", 0),
                    answers: getProp(this.props.resource, "data.list", [])
                        .reduce((memo, it1, i) => {
                            let answer = [];
                            switch (it1.Type) {
                                case "1":
                                case "2":
                                    answer = Object.values(it1.Structure.values).map((it2, j) => {
                                        return !!(this.state.values[i] && this.state.values[i][j]) ? 1 : 0;
                                    });
                                    break;
                                case "3":
                                default:
                                    answer = this.state.values[i];
                                    break;
                            }

                            memo[it1.QuizEntryID] = answer;
                            return memo;
                        }, {}),
                    id: this.getId()
                },
                query: {
                    id: this.getId()
                },
                resource: Resources.CourseQuizEnd
            }));
        })
    };

    toggleConfirmModal = (submit) => {
        this.setState({
            confirmModal: submit
        });
    };

    render() {
        const {translate, resource} = this.props;

        const quiz = getProp(resource, "data.info", []);
        const create = getProp(resource, "create", false);

        const questions = getProp(resource, "data.list", []).map((q, i) => {
            switch (q.Type) {
                case "1":
                    const optionsRadio = Object.values(q.Structure.values).map((it, j) => {
                        return (
                            <div>
                                <label className="margin-right-10">
                                    {it}
                                </label>
                                <FieldRadio
                                    name={"radio_" + i}
                                    onChange={(name, value) => {
                                        let vals = this.state.values;

                                        const l = Object.keys(q.Structure.values).length;
                                        for (let c = 0; c < l; ++c) {
                                            if (!vals[i]) {
                                                vals[i] = {};
                                            }
                                            vals[i][j] = 0;
                                        }
                                        vals[i][j] = 1;
                                        this.setState({
                                            values: vals
                                        });
                                    }}
                                    value={this.state.values[i] ? this.state.values[i][j] : null}
                                />
                            </div>
                        );
                    });
                    return (
                        <div className={"quiz-question"}>
                            <h4>{i + 1}) {q.Question}</h4>
                            {!!q.Instructions && <p>{q.Instructions}</p>}
                            {optionsRadio}
                        </div>
                    );
                case "2":
                    const optionsCheck = Object.values(q.Structure.values).map((it, j) => {
                        return (
                            <div>
                                <label className="margin-right-10">
                                    {it}
                                </label>
                                <FieldCheckbox
                                    onChange={(name, value) => {
                                        let vals = this.state.values;
                                        if (!vals[i]) {
                                            vals[i] = {};
                                        }
                                        vals[i][j] = value;
                                        this.setState({
                                            values: vals
                                        });
                                    }}
                                    value={this.state.values[i] ? this.state.values[i][j] : null}/>
                            </div>
                        );
                    });
                    return (
                        <div className={"quiz-question"}>
                            <h4>{i + 1}) {q.Question}</h4>
                            {!!q.Instructions && <p>{q.Instructions}</p>}
                            {optionsCheck}
                        </div>
                    );
                case "3":
                    const optionsText = Object.values(q.Structure.values).map((it, j) => {
                        return (
                            <div>
                                <FieldText
                                    addClass={"quiz-textarea"}
                                    onChange={(name, value) => {
                                        let vals = this.state.values;
                                        vals[i] = value;
                                        this.setState({
                                            values: vals
                                        });
                                    }}
                                    value={this.state.values[i]}/>
                            </div>
                        );
                    });
                    return (
                        <div className={"quiz-question"}>
                            <h4>{i + 1}) {q.Question}</h4>
                            {!!q.Instructions && <p>{q.Instructions}</p>}
                            {optionsText}
                        </div>
                    );
                case "4":
                    const optionsFreeText = Object.values(q.Structure.values).map((it, j) => {
                        return (
                            <div>
                                <FieldTextarea
                                    addClass="quiz-textarea"
                                    rows={10}
                                    onChange={(name, value) => {
                                        let vals = this.state.values;
                                        vals[i] = value;
                                        this.setState({
                                            values: vals
                                        });
                                    }}
                                    value={this.state.values[i]}/>
                            </div>
                        );
                    });
                    return (
                        <div className={"quiz-question"}>
                            <h4>{i + 1}) {q.Question}</h4>
                            {!!q.Instructions && <p>{q.Instructions}</p>}
                            {optionsFreeText}
                        </div>
                    );
                case "5":
                    const optionsMultiple = Object.keys(q.Structure.values_second)
                        .map((key, j) => {
                        return (
                            <div
                                draggable='true'
                                onDragStart={(event) => {
                                    event
                                        .dataTransfer
                                        .setData('item', JSON.stringify(q.Structure.values_second[key]));
                                    this.setState({
                                        dragged: q.Structure.values_second[key]
                                    });
                                }}
                                onDragEnd={(event) => {
                                    this.setState({
                                        dragged: null
                                    });
                                }}
                                className={"option-spajalica"}>
                                {q.Structure.values_second[key]}
                            </div>
                        );
                    });
                    const optionsFills = Object.values(q.Structure.values)
                        .map((it, j) => {
                        return (
                            <div className={"option-spajalica-fill"}
                                 onDragOver={(event) => {
                                     this.setState({
                                         hover: true
                                     });
                                     event.preventDefault();
                                 }}
                                 onDragLeave={(event) => {
                                     this.setState({
                                         hover: false
                                     });
                                     event.preventDefault();
                                 }}
                                 onDrop={(event) => {
                                     const item = JSON.parse(event
                                         .dataTransfer
                                         .getData('item'));

                                    let spajalices = this.state.spajalices;
                                    if (!spajalices[i]) {
                                        spajalices[i] = {};
                                    }
                                    spajalices[i][j] = item;
                                    this.setState({
                                        spajalices: spajalices
                                    })
                                     event
                                         .dataTransfer
                                         .clearData();
                                 }}
                            >
                                <label className="margin-right-10">
                                    {it} <strong>{(!!this.state.spajalices[i] && !!this.state.spajalices[i][j])?this.state.spajalices[i][j]:""}</strong>
                                </label>
                            </div>
                        );
                    });
                    return (
                        <div className={"quiz-question"}>
                            <h4>{i + 1}) {q.Question}</h4>
                            {!!q.Instructions && <p>{q.Instructions}</p>}
                            {optionsMultiple}

                            {optionsFills}
                        </div>
                    );
            }
        });
        return (
            <PublicLayout  {...this.props}>
                <section className="bgw margin-top-10 margin-bottom-10">
                    <div className=" container quiz-wrapper">
                        <div className="row">
                            {!create && (
                                <div className="col-md-12">
                                    <h3>{quiz.QuizName}</h3>
                                    {quiz.TimeLimit && quiz.TimeLimit > 0 && (
                                        <Timer
                                            initialTime={0}
                                            checkpoints={[
                                                {
                                                    time: quiz.TimeLimit * 60000,
                                                    callback: () => this.submit()
                                                }
                                            ]}
                                        >
                                            {() =>  (
                                                    <React.Fragment>
                                                        <div className={"quiz-timer"}>
                                                            <Timer.Minutes/> <span> {translate("text.minutes")}</span>
                                                            <Timer.Seconds/> <span> {translate("text.seconds")}</span>
                                                            {` / ${quiz.TimeLimit} min`}
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                        </Timer>
                                    )}
                                    { quiz.TimeLimit === null && (
                                        <Timer
                                            initialTime={0}
                                        >
                                            {() =>  (
                                                    <React.Fragment>
                                                        <div className={"quiz-timer"}>
                                                            <Timer.Minutes/> <span> {translate("text.minutes")}</span>
                                                            <Timer.Seconds/> <span> {translate("text.seconds")}</span>
                                                            {` / ${translate("text.unlimited")}`}
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                        </Timer>
                                    )}
                                    <div className="panel course-quiz-panel margin-bottom-10">
                                        <div className="panel-body margin-bottom-10">
                                            {questions}

                                            {resource.isLoading && (
                                                <LoaderSmall addClass={"text-center"}/>
                                            )}
                                        </div>
                                    </div>
                                    {!resource.isLoading && (
                                        <div className="form-buttons-container margin-top-10">
                                            <Button
                                                className={"btn btn-success margin-right-10"}
                                                onClick={() => this.toggleConfirmModal(this.submit)}
                                                text={translate("btn.submit_quiz")}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            {create && (
                                <React.Fragment>
                                    <h2>{translate("text.submit_quiz_done")}</h2>
                                    {!!(+quiz.AutoRate) ? (
                                        <React.Fragment>
                                            <h4>{translate("text.quiz_result")}</h4>
                                            <h3>{resource.create.Score}</h3>
                                        </React.Fragment>
                                    )
                                    :
                                        <React.Fragment>
                                            <h4>{translate("text.waiting_for_result")}</h4>
                                        </React.Fragment>
                                    }
                                    <h3>
                                        <Link to={"/course/" + quiz.CourseID}>{translate("btn.go_to_course")}</Link>
                                    </h3>
                                </React.Fragment>
                            )}

                            <ConfirmQuizDialog
                                text={this.props.translate("message.are_you_sure_submit_quiz")}
                                submit={this.state.confirmModal}
                                cancel={this.toggleConfirmModal}
                                visible={this.state.confirmModal}
                                {...this.props}
                            />
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(QuizView);
