import React, {Component} from "react";
import {getProp} from "../../util/util";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import PrivateLayout from "../../components/private-layout";
import {getResource, resetResourceMessage, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import Pagination from "../../components/pagination";
import LoaderSmall from "../../components/loader-small";
import SuccessMessage from "../../components/success-message";
import {Editor} from "@tinymce/tinymce-react";
import Button from "../../components/button";
import FieldSelectSearch from "../../components/field-select-search";
import ReactPlayer from "react-player";
import FieldText from "../../components/field-text";
import Dropzone from "react-dropzone";
import FieldCheckbox from "../../components/field-checkbox";

class CategoriesView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyid: "LecturePageID",
            addModal: false,
            deleteModal: null,
            selectedItem: null,

            currentPage: 0,
            paginationPage: 1,

            pages: [],
            files: [],

            canUpdate: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                pages: Array.from(getProp(this.props.resource, "data.pages", []))
            });
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId()
            },
            resource: Resources.CourseLectureDetails
        }));
    };

    toggleAddResourceModal = (item = null) => {
        this.setState({
            selectedItem: item,
            addModal: !this.state.addModal
        });
    };

    toggleDeleteResourceModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };

    updateOffset = (offset, num) => {
        this.setState({
            currentPage: offset,
            paginationPage: num,
        });
    };

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({
            canUpdate: false
        }, () => {
            this.props.dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: Object.assign({id: this.getId(), Pages: this.state.pages}),
                resource: Resources.CourseLecture,
                query: {
                    id: this.getId()
                },
                piggyResource: Resources.CourseLectureDetails,
                files: this.state.files
            }))
        });
    };

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    };

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    onDrop = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    cancel = () => {
        this.setState({
            pages: Array.from(getProp(this.props.resource, "data.pages", [])),
            canUpdate: false
        });
    };

    render() {
        const {translate, resource} = this.props;

        const lecture = getProp(resource, "data.lecture", {});

        const pagesCount = this.state.pages.length;

        return (
            <PrivateLayout menuActiveResource={Resources.AdminDashboard} {...this.props}>
                <div className="big-title">
                    <h2 className="related-title">
                        <span>{translate("text.lecture_pages")} ({lecture.LectureName})</span>
                    </h2>
                </div>

                <React.Fragment>
                    {resource.create && !resource.isLoading && (
                        <div>
                            <SuccessMessage className={"action-success-big"} text={translate("label.entry_created")}
                                            onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                        </div>
                    )}

                    {resource.update && !resource.isLoading && (
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                    )}

                    <div className="page-reports-actions">
                        <div className="page-search-wrapper">
                            <button className="btn btn-default margin-right-10"
                                    onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/courses/" + lecture.CourseID)}>
                                <i className="fa fa-angle-double-left"></i> {translate("btn.back_to_course")}
                            </button>

                            <button className="btn btn-primary margin-right-10"
                                    onClick={() => {
                                        let pages = this.state.pages;
                                        pages.splice(this.state.currentPage + 1, 0, {
                                            LectureEntryContent: "",
                                            LectureEntryType: 1,
                                            canUpdate: true
                                        });
                                        this.setState({
                                            pages: pages
                                        })
                                    }}
                            >{translate("btn.add_page_after_current")}
                            </button>

                            <button className="btn btn-primary margin-right-10"
                                    onClick={() => {
                                        let pages = this.state.pages;
                                        pages.push({
                                            LectureEntryContent: "",
                                            LectureEntryType: 1,
                                            canUpdate: true
                                        });
                                        this.setState({
                                            pages: pages
                                        })
                                    }}
                            >{translate("btn.add_page_at_end")}
                            </button>

                            <button className="btn btn-danger"
                                    onClick={() => {
                                        let pages = this.state.pages;
                                        pages.splice(this.state.currentPage, 1);
                                        this.setState({
                                            pages: pages,
                                            currentPage: 0,
                                            canUpdate: true
                                        })
                                    }}
                            >{translate("btn.remove_current_page")}
                            </button>
                        </div>
                    </div>
                    <div>
                        <Pagination
                            addClass="paginationfloat"
                            updateOffset={this.updateOffset}
                            paginationButtonLimit={5}
                            limit={1}
                            hideCaption={true}
                            hidePrevNext={true}
                            offset={this.state.currentPage}
                            count={pagesCount}
                            paginationPage={this.state.paginationPage}
                        />

                        {!resource.isLoading && (this.state.pages.length > 0) && (
                            <div>
                                <div className="form-element-container">
                                    <div className="element-name">{translate("field.lecture_type")}</div>
                                    <div className="element-input">
                                        <FieldSelectSearch onChange={(name, value) => {
                                            let pages = this.state.pages;
                                            pages[this.state.currentPage] = {
                                                LectureEntryContent: "",
                                                LectureEntryDescription: "",
                                                LectureEntryType: value
                                            };
                                            this.setState({
                                                pages: pages,
                                                canUpdate: true
                                            })

                                        }}
                                                           addClass="margin-bottom-10"
                                                           values={{
                                                               1: translate("text.type_free_text"),
                                                               2: translate("text.type_file"),
                                                               3: translate("text.type_video")
                                                           }}
                                                           name={"x"}
                                                           value={this.state.pages[this.state.currentPage].LectureEntryType}>
                                        </FieldSelectSearch>
                                    </div>
                                </div>

                                {(this.state.pages[this.state.currentPage].LectureEntryType == 1) && (
                                    <React.Fragment>
                                        <div className="form-element-container">
                                            <div className="element-name">{translate("field.description")}</div>
                                            <div className="element-input">
                                                <FieldText onChange={(name, value) => {
                                                    let pages = this.state.pages;
                                                    pages[this.state.currentPage].LectureEntryDescription = value;
                                                    this.setState({
                                                        pages: pages,
                                                        canUpdate: true
                                                    })
                                                }}
                                                           value={this.state.pages[this.state.currentPage].LectureEntryDescription}
                                                           name={"x"}
                                                           addClass={"form-control margin-bottom-10"}/>
                                            </div>
                                        </div>

                                        <div className="form-element-container">
                                            <div className="element-name">{translate("field.content")}</div>
                                            <Editor
                                                scriptLoading={{
                                                    async: true,
                                                    delay: 1500
                                                }}
                                                key={this.state.currentPage}
                                                apiKey={"260itthuh2ep794xwtdj2pv11bivfhikitkg5id18z8v4bxu"}
                                                value={this.state.pages[this.state.currentPage].LectureEntryContent}
                                                init={{
                                                    strict_loading_mode : true,
                                                    external_plugins: { tiny_mce_wiris: 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.min.js' },
                                                    extended_valid_elements: '*[.*]',

                                                    height: 500,
                                                    plugins: [
                                                        'advlist autolink lists link media image charmap print preview anchor image',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                        'undo redo | image | formatselect | bold italic backcolor | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat | help | \
                                                        tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
                                                    file_picker_types: 'image',
                                                    /* and here's our custom image picker*/
                                                    file_picker_callback: function (cb, value, meta) {
                                                        var input = document.createElement('input');
                                                        input.setAttribute('type', 'file');
                                                        input.setAttribute('accept', 'image/*');

                                                        /*
                                                         Note: In modern browsers input[type="file"] is functional without
                                                         even adding it to the DOM, but that might not be the case in some older
                                                         or quirky browsers like IE, so you might want to add it to the DOM
                                                         just in case, and visually hide it. And do not forget do remove it
                                                         once you do not need it anymore.
                                                         */

                                                        input.onchange = function () {
                                                            var file = this.files[0];

                                                            var reader = new FileReader();
                                                            reader.onload = function () {
                                                                /*
                                                                 Note: Now we need to register the blob in TinyMCEs image blob
                                                                 registry. In the next release this part hopefully won't be
                                                                 necessary, as we are looking to handle it internally.
                                                                 */
                                                                var id = 'blobid' + (new Date()).getTime();
                                                                var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                var base64 = reader.result.split(',')[1];
                                                                var blobInfo = blobCache.create(id, file, base64);
                                                                blobCache.add(blobInfo);

                                                                /* call the callback and populate the Title field with the file name */
                                                                cb(blobInfo.blobUri(), {title: file.name});
                                                            };
                                                            reader.readAsDataURL(file);
                                                        };

                                                        input.click();
                                                    }
                                                }}
                                                onEditorChange={(content, editor) => {
                                                    let pages = this.state.pages;
                                                    pages[this.state.currentPage].LectureEntryContent = content;
                                                    this.setState({
                                                        pages: pages,
                                                        canUpdate: true
                                                    })
                                                }}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                                {(this.state.pages[this.state.currentPage].LectureEntryType == 2) && (
                                    <React.Fragment>
                                        <div className="form-element-container">
                                            <div className="element-name">{translate("field.description")}</div>
                                            <div className="element-input">
                                                <FieldText onChange={(name, value) => {
                                                    let pages = this.state.pages;
                                                    pages[this.state.currentPage].LectureEntryDescription = value;
                                                    this.setState({
                                                        pages: pages,
                                                        canUpdate: true
                                                    })
                                                }}
                                                           value={this.state.pages[this.state.currentPage].LectureEntryDescription}
                                                           name={"x"}
                                                           addClass={"form-control margin-bottom-10"}/>
                                            </div>
                                        </div>

                                        <div className="form-element-container">
                                            <div className="element-name">{translate("field.can_download")}</div>
                                            <div className="element-input">
                                                <FieldCheckbox onChange={(name, value) => {
                                                    let pages = this.state.pages;
                                                    pages[this.state.currentPage].CanDownload = !!value ? 1 : 0;
                                                    this.setState({
                                                        pages: pages,
                                                        canUpdate: true
                                                    })
                                                }}
                                                               value={!!this.state.pages[this.state.currentPage].CanDownload && (this.state.pages[this.state.currentPage].CanDownload != 0)}
                                                               name={"x"}
                                                               addClass={"form-control margin-bottom-10"}/>
                                            </div>
                                        </div>

                                        <div className="form-element-container">
                                            <div className="element-name">{translate("field.content")} {
                                                this.state.pages[this.state.currentPage].LectureEntryContent
                                            }</div>

                                            <Dropzone onDrop={(acceptedFiles) => {
                                                const files = this.state.files;
                                                files[this.state.currentPage] = acceptedFiles[0];
                                                let pages = this.state.pages;
                                                pages[this.state.currentPage].LectureEntryContent = acceptedFiles[0].name;
                                                this.setState({
                                                    files: files,
                                                    pages: pages,
                                                    canUpdate: true
                                                });
                                            }}
                                                      onDragEnter={this.onDragEnter}
                                                      onDragLeave={this.onDragLeave}
                                                      onDropAccepted={this.onDrop}
                                                      maxFiles={1}
                                                      multiple={false}
                                            >
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}
                                                             className={"dropzone " + (this.state.dropzoneActive ? "active" : "")}>
                                                            <input {...getInputProps()} />
                                                            <p>Drag 'n' drop some files here, or click to select
                                                                files</p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </React.Fragment>
                                )}
                                {(this.state.pages[this.state.currentPage].LectureEntryType == 3) && (
                                    <React.Fragment>
                                        <div className="form-element-container">
                                            <div className="element-name">{translate("field.description")}</div>
                                            <div className="element-input">
                                                <FieldText onChange={(name, value) => {
                                                    let pages = this.state.pages;
                                                    pages[this.state.currentPage].LectureEntryDescription = value;
                                                    this.setState({
                                                        pages: pages,
                                                        canUpdate: true
                                                    })
                                                }}
                                                           value={this.state.pages[this.state.currentPage].LectureEntryDescription}
                                                           name={"x"}
                                                           addClass={"form-control margin-bottom-10"}/>
                                            </div>
                                        </div>

                                        <div className="form-element-container">
                                            <div className="element-name">{translate("field.content")}</div>
                                            <div className="element-input">
                                                <FieldText onChange={(name, value) => {
                                                    let pages = this.state.pages;
                                                    pages[this.state.currentPage].LectureEntryContent = value;
                                                    this.setState({
                                                        pages: pages,
                                                        canUpdate: true
                                                    })
                                                }} value={this.state.pages[this.state.currentPage].LectureEntryContent}
                                                           name={"x"}
                                                           addClass={"form-control margin-bottom-10"}/>
                                            </div>
                                            {!!this.state.pages[this.state.currentPage].LectureEntryContent && (
                                                <ReactPlayer
                                                    className='react-player'
                                                    url={this.state.pages[this.state.currentPage].LectureEntryContent}
                                                    width='100%'
                                                    height='100%'
                                                    playing={true}
                                                    loop={false}
                                                    volume={1}
                                                    muted={false}
                                                    controls={true}
                                                />
                                            )}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        )}

                        <div className="form-buttons-container margin-top-10">
                            <Button
                                className={"btn btn-success margin-right-10" + (!this.state.canUpdate ? " disabled" : "")}
                                onClick={this.state.canUpdate ? this.submit : () => {
                                }}
                                text={translate("btn.update")}
                            />

                            <Button
                                className={"btn btn-warning" + (!this.state.canUpdate ? " disabled" : "")}
                                onClick={this.state.canUpdate ? this.cancel : () => {
                                }}
                                text={translate("btn.undo")}
                            />
                        </div>

                        {resource.isLoading && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}
                    </div>
                </React.Fragment>

                <ConfirmDialog
                    text={this.props.translate("message.are_you_sure_delete_resourceName")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteResourceModal}
                    visible={this.state.deleteModal}
                    {...this.props}
                />
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(CategoriesView);
