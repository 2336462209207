import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../util/util";
import Resources from "../../data/services/resources";
import FieldText from "../../components/field-text";
import Env from "../../util/env";
import {Field, FieldsManager} from "../../data/services/fields";
import {getSecondResource} from "../../data/actions/secondResource";

class CoursesView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            queryFilterFields: this.getQueryFilterFields()
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
        this.fetchCategories();
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.CoursesList
        }))
    };

    fetchCategories = () => {
        this.props.dispatch(getSecondResource({
            resource: Resources.CoursePublicCategories
        }))
    };

    /** UI Events
     ================================================================= */
    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value)
        }, this.fetchData)
    }

    /** Helpers
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'text'),
            CourseCategoryID: new Field('query', '', [''], false, 'text'),
        }
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, secondResource} = this.props;

        const loggedIn = !!LocalStorage.get('user');

        const listed = getProp(resource, "data.listed", []).reduce((memo, item) => {
            memo[item.CourseID] = {
                Completed: item.Completed,
                Enrolled: true
            };
            return memo;
        }, {});

        const myCourses = getProp(resource, "data.list", [])
            .filter(it => !!listed[it.CourseID])
            .map((item, i) => (
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <Link title={item.CourseName} className={"link-like-element"} to={"/course/" + item.CourseID}>
                        <div className="shop-item">
                            <div className="post-media entry">
                                {(listed[item.CourseID]) && (<div className="ribbon-wrapper-green">
                                    <div
                                        className="ribbon-green">{translate((listed[item.CourseID].Completed == 1)  ? "field.Completed" : "text.enrolled")}</div>
                                </div>)}
                                <img src={Env.getApiUrl("/" + item.CourseConfImagePath)} alt=""
                                     className="img-responsive"/>
                                {!!item.CoursePromoText && (
                                    <div className="ribbon-wrapper-left">
                                        <div className="ribbon-left">{item.CoursePromoText}</div>
                                    </div>
                                )}
                            </div>
                            <div className="shop-desc">
                                <div className="shop-price clearfix">
                                    <div className="pull-left">

                                    </div>
                                </div>
                                <h3>{item.CourseName}</h3>
                            </div>

                            <div className="large-post-meta">
                                {item.CourseShortDesc}
                            </div>
                        </div>
                    </Link>
                </div>
            ));

        const courses = getProp(resource, "data.list", [])
            .filter(it => !listed[it.CourseID])
            .map((item) => (
                <div className="course-single">
                    <Link title={item.CourseName} className={"link-like-element"} to={"/course/" + item.CourseID}>
                        <div className="shop-item">
                            <div className="post-media entry">
                                {(listed[item.CourseID]) && (<div className="ribbon-wrapper-green">
                                    <div
                                        className="ribbon-green">{translate((listed[item.CourseID].Completed == 1) ? "field.Completed" : "text.enrolled")}</div>
                                </div>)}
                                <img src={Env.getApiUrl("/" + item.CourseConfImagePath)} alt=""
                                     className="img-responsive"/>
                                {!!item.CoursePromoText && (
                                    <div className="ribbon-wrapper-left">
                                        <div className="ribbon-left">{item.CoursePromoText}</div>
                                    </div>
                                )}
                            </div>
                            <div className="shop-desc">
                                <div className="shop-price clearfix">
                                    <div className="pull-left">

                                    </div>
                                </div>
                                <h3>{item.CourseName}</h3>
                            </div>

                            <div className="large-post-meta">
                                {item.CourseShortDesc}
                            </div>
                        </div>
                    </Link>
                </div>
            ));

        const cats = getProp(secondResource, "data.list", []).reduce((memo, item) => {
            memo.push(<div
                onClick={() => this.handleFilterInputChange("CourseCategoryID", item.CourseCategoryID)}
                className={"btn btn-primary category word-break wid-100 text-align-left " + ((this.state.queryFilterFields.CourseCategoryID === item.CourseCategoryID) ? " active" : "")}
            >
                <span className={"color-white word-break p-span"}>{item.CourseCategoryName}</span>
            </div>)

            return memo;
        }, [(
            <div
                onClick={() => this.handleFilterInputChange("CourseCategoryID", '')}
                className={"btn btn-primary wid-100 category text-align-left" + (!this.state.queryFilterFields.CourseCategoryID ? " active" : "")}
            >
                <span className={"color-white word-break p-span"}>{translate("txt.all")}</span>

            </div>
        )]);

        return (
            <PublicLayout menuActiveResource={Resources.CoursesList} {...this.props}>
                <section className="section bgw">
                    <div className="container">
                        <div className="row course-wrapper">
                            {!!loggedIn && !!myCourses.length && (
                                <React.Fragment>
                                    <h2>{translate("text.mycourses")}</h2>
                                    <div className="row">
                                        {myCourses}
                                    </div>
                                </React.Fragment>
                            )}
                            <h2>{translate("text.allcourses")}</h2>
                            <div>
                                <span className={"margin-10"}>
                                    {translate("text.search")}
                                </span>
                                <FieldText
                                    onChange={this.handleFilterInputChange}
                                    {...this.state.queryFilterFields.query}
                                />
                            </div>
                            <div className={"cats-courses mt-25"}>
                                <div className={"word-break"}>
                                    {cats}
                                </div>
                                <div className="margin-top-10">
                                    {resource.isLoading && (
                                        <LoaderSmall addClass={"text-center"}/>
                                    )}
                                    {courses}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(CoursesView);
