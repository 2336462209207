import React from "react";
import LocalStorage from "./localStorage";
import moment from "moment";
import FieldDate from "../components/field-date";
import FieldText from "../components/field-text";
import FieldCheckbox from "../components/field-checkbox";
import FieldTextarea from "../components/field-textarea";
import FieldSelectSearch from "../components/field-select-search";
import FieldRange from '../components/field-range';
import {Editor} from '@tinymce/tinymce-react';
import {processResponse} from "../data/services/api-util";
import axios from "axios";
import Env from "./env";
import FieldDropdownSelect from "../components/field-dropdown-select";
import FieldTinyMCE from "../components/field-tinymce";

export const READ_PERM = 1;
export const CREATE_PERM = 2;
export const UPDATE_PERM = 4;
export const DELETE_PERM = 8;

export function checkFeature(user, feature) {
    const companyFeatures = getProp(user, "Contact.features", "").split(",");
    return companyFeatures.includes(feature);
}

export function checkPerm(key, check) {
    const perm = parseInt(getProp(LocalStorage.get('user'), 'permissions.' + key, 0));

    return (perm & check) !== 0;
}

export function getProp(object, keys, defaultVal) {
    if (object === undefined || object === null) {
        return defaultVal;
    }
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return (object === undefined || object === null) ? defaultVal : object;
}

export function getCurrentTimeSeconds() {
    const d = new Date();
    return Math.round(d.getTime() / 1000);
}

export function toBackDateTime(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000") {
        return "no data available";
    }
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss") : "no data available");
}

export function toBackDate(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000") {
        return "no data available";
    }
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD") : "no data available");
}

export function numberWithCommasInputChange(n) {
    if (n) {
        let original = n.toString().split(".");
        if (original[1] && original[1].length > 1) {
            return original[0] + (original[1] ? "." + original[1].charAt(0) + original[1].charAt(1) : "");
        } else {
            n = n.replace(/[^0-9.]/g, '');
            n = n.replace(/,/g, "");
            n = n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return n;
        }
    }
}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function numberWithCommas(n) {
    if (!isNaN(n) && (n != "") && (n !== null)) {

        let result = formatMoney(n, 2, ".", ",");
        if (result == 0) {
            return 0;
        } else {
            return result;
        }
    }

    return "";
}

export function toFrontDate(dateEntered) {
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("DD/MM/YYYY") : "no data available");
}

export function toFrontDateTime(dateEntered) {
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("DD/MM/YYYY HH:mm") : "no data available");
}

export function fieldsToCells(fields, translate, item, select = {},) {
    return Object.keys(fields).map((key) => {
        switch (fields[key].type) {
            case 'checkbox':
                return (
                    <td>{(!!item[key] && item[key] != 0) ? translate("text.yes") : translate("text.no")}</td>
                );
            case 'role':
                return (
                    <td>{item['role_name']}</td>
                );
            case 'select':
                switch (key) {
                    default:
                        return (
                            <td>{select[key]}</td>
                        );
                }
            case 'date':
                return (
                    <td>{toFrontDate(item[key])}</td>
                );

            default:
                const formats = ['float', 'float_or_empty', 'integer', 'integer_or_empty'];
                if (fields[key].validate.filter(value => formats.includes(value)).length > 0) {
                    return (
                        <td>{numberWithCommas(item[key])}</td>
                    );
                }
                return (
                    <td>{item[key]}</td>
                );
        }
    })
}

export function isValidURL(str) {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}
export function ifContainsURL(str) {
    if(new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(str)) {
        return true
    }else {
        return false
    }
}

export function fieldsToHtml(fieldsCpy, translate, handleInputChange, split = false, selects = {}) {
    let result = fieldsCpy.reduce((memo, item) => {

        const req = (item.validate.includes("empty") || item.validate.includes("integer") || item.validate.includes("float"));

        let field = null;
        if (item.type === 'user') {
            field = (
                <React.Fragment>
                    <FieldDropdownSelect
                        {...item}
                        onChange={(name, value) => {
                            handleInputChange(name, value);
                        }}
                        addClass={"width-90 inline-block"}
                        loadOptions={
                            (inputValue, callback) => {
                                axios.get(
                                    Env.getApiUrl('api/users', {query: inputValue}),
                                    {
                                        headers: {
                                            'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                        }
                                    }
                                )
                                    .then((response) => {
                                        const result = processResponse(response);
                                        if (result && result.status === 0) {
                                            const list = result.data.list.map((item) => {
                                                return {
                                                    value: item.user_id,
                                                    label: item.user_fname + " " + item.user_lname + "(" + item.user_email + ")"
                                                }
                                            });
                                            callback(list);
                                        }
                                    })
                                    .catch((error) => {
                                    });
                            }
                        }
                        defaultOptions
                    />
                </React.Fragment>
            );
        } else if (item.type === 'role') {
            const values = {
                "1": "Admin",
                "4": "Manager",
                "2": "Teacher",
                "3": "Student"
            };

            field = (
                <div className="form-element-container">
                    <div className="element-name">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldSelectSearch onChange={handleInputChange}
                                           values={values}
                                           {...item}>
                        </FieldSelectSearch>
                    </div>
                </div>
            );
        } else if (item.type === 'custom') {
            field = selects[item.name];
        } else if (item.type === "tiny") {
            field = (
                <FieldTinyMCE
                    {...item}
                    onChange={handleInputChange}
                    translate={translate}
                />
            );

        } else if (item.type === "textarea") {
            field = (
                <div className="form-element-container">
                    <div className="element-name">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldTextarea onChange={handleInputChange} {...item} />
                    </div>
                </div>
            );
        } else if (item.type === "checkbox") {
            field = (
                <div className="form-element-container">
                    <div className="element-name">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldCheckbox onChange={handleInputChange} {...item} value={!!item.value && item.value != 0}/>
                    </div>
                </div>
            );
        } else if (item.type === "select") {
            let values = {};
            if (!!selects[item.name]) {
                values = selects[item.name];
            }

            field = (
                <div className="form-element-container">
                    <div className="element-name">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldSelectSearch onChange={handleInputChange}
                                           values={values}
                                           {...item}>
                        </FieldSelectSearch>
                    </div>
                </div>
            );
        } else if (item.type === "datetime") {
            field = (
                <div className="form-element-container">
                    <div
                        className="element-name align-vertical-middle">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldDate onChange={handleInputChange} {...item} />
                    </div>
                </div>
            );
        } else if (item.type === "date") {
            field = (
                <div className="form-element-container">
                    <div
                        className="element-name align-vertical-middle">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldDate onChange={handleInputChange} {...item} />
                    </div>
                </div>
            );
        } else if (item.type === "range") {
            field = (
                <div className="form-element-container">
                    <div className="element-name align-vertical-middle">{translate("field." + item.name)}{item.value === null || item.value == 0 ? ` = ${translate("text.unlimited")}` : ` = ${item.value}`}</div>
                    <div className="element-input">
                        <FieldRange onChange={handleInputChange} selects={selects[item.name]} {...item} />
                    </div>
                </div>
            );
        } else if (item.type === "hidden") {

        } else {
            field = (
                <div className="form-element-container">
                    <div className="element-name">{translate("field." + item.name)}{req ? "*" : ""}</div>
                    <div className="element-input">
                        <FieldText onChange={handleInputChange} {...item} addClass={"form-control"}/>
                    </div>
                </div>
            );
        }
        memo.push(field);

        return memo;
    }, []);

    if (split) {
        const count = result.length;
        const leftSide = result.slice(0, Math.floor(count / 2));
        const rightSide = result.slice(Math.floor(count / 2), count);

        return [leftSide, rightSide];
    }

    return result;
}

export function returnMonth(month){
    switch (month){
        case 1:
            return "text.january"
        case 2:
            return "text.february"
        case 3:
            return "text.march"
        case 4:
            return "text.april"
        case 5:
            return "text.may"
        case 6:
            return "text.june"
        case 7:
            return "text.july"
        case 8:
            return "text.august"
        case 9:
            return "text.september"
        case 10:
            return "text.october"
        case 11:
            return "text.november"
        case 12:
            return "text.december"
    }
}
