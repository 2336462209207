import React, {useEffect, useState} from "react";
import {Editor} from "@tinymce/tinymce-react";
import LoaderSmall from "../loader-small";

const FieldTinyMCE = (props) => {
    const externalPluginAwaitDelay = 1500
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      setTimeout(() => {
          setIsLoading(false)
      }, externalPluginAwaitDelay)
    },[])

    return (
        <React.Fragment>
            <div className="form-element-container">
                <div className="element-name">{props.translate("field." + props.name)}{!!props.validate.length ? "*" : ""}</div>
                <div className="element-input">
                    {isLoading && <LoaderSmall addClass={"text-center"}/>}
                    <Editor
                        scriptLoading={{
                            async: true,
                            delay: externalPluginAwaitDelay
                        }}
                        apiKey={"260itthuh2ep794xwtdj2pv11bivfhikitkg5id18z8v4bxu"}
                        value={props.value}
                        init={{
                            strict_loading_mode : true,
                            external_plugins: { tiny_mce_wiris: 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.min.js' },
                            extended_valid_elements: '*[.*]',
                            height: 500,
                            content_style: "",
                            plugins: [
                                'advlist autolink lists link media image charmap print preview anchor image',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | image | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help | \
                                tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
                            file_picker_types: 'image',
                            /* and here's our custom image picker*/
                            file_picker_callback: function (cb, value, meta) {
                                var input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.setAttribute('accept', 'image/*');

                                /*
                                 Note: In modern browsers input[type="file"] is functional without
                                 even adding it to the DOM, but that might not be the case in some older
                                 or quirky browsers like IE, so you might want to add it to the DOM
                                 just in case, and visually hide it. And do not forget do remove it
                                 once you do not need it anymore.
                                 */

                                input.onchange = function () {
                                    var file = this.files[0];

                                    var reader = new FileReader();
                                    reader.onload = function () {
                                        /*
                                         Note: Now we need to register the blob in TinyMCEs image blob
                                         registry. In the next release this part hopefully won't be
                                         necessary, as we are looking to handle it internally.
                                         */
                                        var id = 'blobid' + (new Date()).getTime();
                                        var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                        var base64 = reader.result.split(',')[1];
                                        var blobInfo = blobCache.create(id, file, base64);
                                        blobCache.add(blobInfo);

                                        /* call the callback and populate the Title field with the file name */
                                        cb(blobInfo.blobUri(), {title: file.name});
                                    };
                                    reader.readAsDataURL(file);
                                };

                                input.click();
                            }
                        }}
                        onEditorChange={(content, editor) => {
                            props.onChange(props.name, content);
                        }}
                    />
                    {props.errorMessage && (
                        <div className={"invalid-feedback"}>
                            {props.errorLabelMessage ? props.errorLabelMessage : "Field required*"}
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

export default FieldTinyMCE
