import React, {Component} from "react";
import {Field} from "../../data/services/fields";
import {getProp} from "../../util/util";
import PrivateLayout from "../../components/private-layout";
import {getResource, resetResourceMessage, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import LoaderSmall from "../../components/loader-small";
import SuccessMessage from "../../components/success-message";
import {Editor} from '@tinymce/tinymce-react';
import Button from "../../components/button";

class AdminSettingsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                'user_fname': new Field('user_fname', '', ['empty'], false),
                'user_lname': new Field('user_lname', '', ['empty'], false),
                'user_email': new Field('user_email', '', ['empty'], false, 'readonly'),
                'user_phone': new Field('user_phone', '', [], false),
                'role_id': new Field('role_id', '', ['empty'], false, 'role')
            },
            pages: {
                front: "",
                about: ""
            },
            currentPage: 0,
            canUpdate: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading && !!getProp(this.props, "resource.data", false)) {
            this.setState({
                pages: {
                    front: getProp(this.props, "resource.data.front", ""),
                    about: getProp(this.props, "resource.data.about", "")
                }
            });
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: this.getResourceName()
        }))
    };

    getResourceName = () => {
        return Resources.Settings;
    }

    submit = (event) => {
        event && event.preventDefault();

        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                pages: this.state.pages
            },
            resource: Resources.Settings,
            piggyResource: Resources.Settings
        }))
    };

    cancel = () => {
        this.setState({
            pages: {
                front: getProp(this.props, "resource.data.front", ""),
                about: getProp(this.props, "resource.data.about", "")
            }, canUpdate: false
        });
    };

    render() {
        const {translate, resource} = this.props;

        const resourceName = this.getResourceName();

        const css = [...document.styleSheets]
            .map(styleSheet => {
                try {
                    return [...styleSheet.cssRules]
                        .map(rule => rule.cssText)
                        .join('');
                } catch (e) {
                    console.log('Access to stylesheet %s is denied. Ignoring...', styleSheet.href);
                }
            })
            .filter(Boolean)
            .join(" ");

        const editors = Object.keys(this.state.pages).map((it) => {
            const val = getProp(this.props, "resource.data." + it, "");

            return (
                <Editor
                    scriptLoading={{
                        async: true,
                        delay: 1500
                    }}
                    key={it}
                    apiKey={"260itthuh2ep794xwtdj2pv11bivfhikitkg5id18z8v4bxu"}
                    value={val}
                    init={{
                        strict_loading_mode : true,
                        external_plugins: { tiny_mce_wiris: 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.min.js' },
                        extended_valid_elements: '*[.*]',

                        height: 500,
                        plugins: [
                            'advlist autolink lists link media image charmap print preview anchor image',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        content_style: css,
                        toolbar:
                            'undo redo | image | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | \
                            tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
                        file_picker_types: 'image',
                        /* and here's our custom image picker*/
                        file_picker_callback: function (cb, value, meta) {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            /*
                             Note: In modern browsers input[type="file"] is functional without
                             even adding it to the DOM, but that might not be the case in some older
                             or quirky browsers like IE, so you might want to add it to the DOM
                             just in case, and visually hide it. And do not forget do remove it
                             once you do not need it anymore.
                             */

                            input.onchange = function () {
                                var file = this.files[0];

                                var reader = new FileReader();
                                reader.onload = function () {
                                    /*
                                     Note: Now we need to register the blob in TinyMCEs image blob
                                     registry. In the next release this part hopefully won't be
                                     necessary, as we are looking to handle it internally.
                                     */
                                    var id = 'blobid' + (new Date()).getTime();
                                    var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                    var base64 = reader.result.split(',')[1];
                                    var blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);

                                    /* call the callback and populate the Title field with the file name */
                                    cb(blobInfo.blobUri(), {title: file.name});
                                };
                                reader.readAsDataURL(file);
                            };

                            input.click();
                        }
                    }}
                    onEditorChange={(content, editor) => {
                        this.setState({
                            canUpdate: true,
                            pages: Object.assign(this.state.pages, {[it]: content})
                        });
                    }}
                />
            )
        });

        const pages = Object.keys(this.state.pages).map((it, i) => {

            return (
                <div
                    onClick={() => this.setState({currentPage: i})}
                    className={"page_edit " + ((i === this.state.currentPage) ? "active" : '')}>
                    {translate("page." + it)}
                </div>)
        });
        return (
            <PrivateLayout {...this.props} menuActiveResource={Resources.Settings}>
                <div className="big-title">
                    <h2 className="related-title">
                        <span>{translate(resourceName)}</span>
                    </h2>
                </div>

                <h3>{translate("text.pages")}</h3>

                {resource.update && !resource.isLoading && (
                    <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                    onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                )}

                <div>
                    {pages}
                </div>

                {resource.isLoading && (
                    <LoaderSmall addClass={"text-center"}/>
                )}

                <div>
                    {!resource.isLoading && editors[this.state.currentPage]}
                </div>

                <div className="form-buttons-container margin-top-10">
                    <Button
                        className={"btn btn-success margin-right-10" + (!this.state.canUpdate ? " disabled" : "")}
                        onClick={this.state.canUpdate ? this.submit : () => {
                        }}
                        text={translate("btn.update")}
                    />

                    {/*
                    <Button
                        className={"btn btn-warning" + (!this.state.canUpdate?" disabled":"")}
                        onClick={this.state.canUpdate?this.cancel:()=>{}}
                        text={translate("btn.undo")}
                    />
                    */}
                </div>
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(AdminSettingsView);
