import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/public-layout";
import Resources from "../../data/services/resources";
import LoaderSmall from "../../components/loader-small";
import {downloadDocument, getResource, resetResourceMessage, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {getProp, toFrontDateTime, validURL} from "../../util/util";
import {getSecondResource} from "../../data/actions/secondResource";
import ReactPlayer from "react-player";
import Env from "../../util/env";
import FileViewer from 'react-file-viewer';
import {Field} from "../../data/services/fields";
import AddResourceDialog from "../../components/dialog/add-resource";
import SuccessMessage from "../../components/success-message";
import ConfirmQuizDialog from "../../components/dialog/confirm-quiz-dialog";

const TAB_INFO_ID = 1;
const TAB_LECTURES_ID = 2;
const TAB_QUIZ_ID = 3;
const TAB_FORUM_ID = 4;
const TAB_CERT_ID = 5;

class HomeView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadedTab: 1,
            lectureLoaded: false,
            forumThreadLoaded: false,
            currentPage: 0,
            paginationPage: 1,
            expandedLectureWindow: false,
            addForumMessageModal: false,
            forumMessageFields: {
                'MessageContent': new Field('MessageContent', '', ['empty'], false)
            },
            seens: {}
        };
        this.myRef = React.createRef()
    }

    componentDidMount() {
        this.props.dispatch(resetResourceMessage());
        this.fetchData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.user.data === null) && (!!this.props.user.data)) {
            this.props.history.push(process.env.PUBLIC_URL + "/");
        }
    };

    fetchData = () => {
        const id = this.props.match.params && this.props.match.params.id;

        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: id
            },
            resource: this.getResourceName(this.state.loadedTab)
        }))
    };

    getResourceName = (tab) => {
        switch (tab) {
            // case TAB_INFO_ID:
            //     return Resources.CoursesDetails
            // case TAB_LECTURES_ID:
            //     return Resources.CoursesDetailsLectures
            // case TAB_QUIZ_ID:
            //     return Resources.CoursesDetailsQuizes
            // case TAB_FORUM_ID:
            //     return Resources.CoursesDetailsForum
            default:
                return Resources.CoursesDetails
        }
    }

    enroll = () => {
        const loggedIn = !!LocalStorage.get('user');

        if (!loggedIn) {
            this.props.history.push(process.env.PUBLIC_URL + "/login");
        }

        const id = this.props.match.params && this.props.match.params.id;

        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            query: {
                id: id
            },
            params: {
                id: id
            },
            resource: Resources.CourseEnroll,
            piggyResource: Resources.CoursesDetails
        }))
    };

    loadLecture = (id) => {
        const seens = this.state.seens;
        seens[id] = true;
        this.setState({
            lectureLoaded: id,
            currentPage: 0,
            seens: seens,
        }, () => {
            this.props.dispatch(getSecondResource({
                user: LocalStorage.get("user"),
                query: {
                    id: id
                },
                resource: Resources.CourseLectureDetails
            }))
        })
    };

    loadForumThread = (id) => {
        this.setState({
            forumThreadLoaded: id
        }, () => {
            this.props.dispatch(getSecondResource({
                user: LocalStorage.get("user"),
                query: {
                    id: id
                },
                resource: Resources.CourseForumMessages
            }))
        })
    };

    updateOffset = (offset, num) => {
        this.setState({
            currentPage: offset,
            paginationPage: num,
        });
    };

    closeLecture = () => {
        this.setState({
            lectureLoaded: false,
            currentPage: 0
        });
    };

    closeForum = () => {
        this.setState({
            forumThreadLoaded: false
        });
    };

    toggleAddForumModal = () => {
        this.setState({
            addForumMessageModal: !this.state.addForumMessageModal
        })
    };

    switchTab = (index) => {
        if (index === 5) {
            const id = this.props.match.params && this.props.match.params.id;
            this.props.dispatch(downloadDocument({
                user: LocalStorage.get("user"),
                query: {
                    id: id,
                    name: "certificate.pdf"
                },
                resource: Resources.CourseCert
            }));
            return;
        }
        this.setState({
            loadedTab: index
        });
    };

    toggleExpandLectureWindow = () => {
        const expanded = !this.state.expandedLectureWindow;

        this.setState({
            expandedLectureWindow: expanded
        });

        if (expanded) {
            document.body.classList.add('disable-scroll-y');
        } else {
            document.body.classList.remove('disable-scroll-y');
        }
    };

    startQuiz = (id) => {
        this.props.history.push(process.env.PUBLIC_URL + "/quiz/start/" + id);
    };

    toggleConfirmModal = (submit) => {
        this.setState({
            confirmModal: submit
        });
    };

    scrollToElm = () => {
        let box = document.querySelector('.expanded-window-active');
        let targetElm = document.querySelector('.custom-page-curse' );
        if (box && targetElm) {
            let pos = this.getRelativePos(targetElm);
            this.scrollTo(box, pos.top, 0.5);
        }
    };

    getRelativePos = (elm) => {
        let pPos = elm.parentNode.getBoundingClientRect(), // parent pos
            cPos = elm.getBoundingClientRect(), // target pos
            pos = {};
        pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop;
        pos.right = cPos.right - pPos.right;
        pos.bottom = cPos.bottom - pPos.bottom;
        pos.left = cPos.left - pPos.left;
        return pos;
    };

    scrollTo = (element, to, duration, onDone) => {
        let start = element.scrollTop,
            change = to - start,
            startTime = performance.now(),
            val, now, elapsed, t;
        function animateScroll() {
            now = performance.now();
            elapsed = (now - startTime) / 1000;
            t = (elapsed / duration);
            element.scrollTop = start + change * (t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
            if (t < 1)
                window.requestAnimationFrame(animateScroll);
            else
                onDone && onDone();
        }
        animateScroll();
    };

    render() {
        const {translate, resource, secondResource} = this.props;
        const canSeeLectures = getProp(resource, "data.canSeeLectures", false);

        const seens = Object.assign({}, this.state.seens, getProp(resource, "data.seens", []).reduce((memo, item) => {
            memo[item.LectureID] = true;
            return memo;
        }, {}));

        let currLectNum = 0;
        let canSeeUpToo = 0;
        let preReqDone = true;
        const dateLectures = getProp(resource, "data.lectures", []);
        const lectures = dateLectures.map((item, i) => {
            if (item.LectureID === this.state.lectureLoaded) {
                currLectNum = i;
            }
            const canSee = canSeeLectures && preReqDone;
            preReqDone &= !item.LectureRequired || (item.LectureRequired && seens[item.LectureID]);
            if (!preReqDone) {
                canSeeUpToo = i;
            }
            return (
                <tr>
                    <td>
                        {seens[item.LectureID] && (
                            <i className="fa fa-check"></i>
                        )}
                        {!seens[item.LectureID] && (
                            <i className="fa-check gray-check"></i>
                        )}
                    </td>
                    <td className={"font-bold" + (canSee ? " link-like-element has-cursor" : "")}
                        onClick={() => canSee ? this.loadLecture(item.LectureID) : () => {
                        }}>
                        {item.LectureName}
                    </td>
                    <td>{item.LectureShortDesc}</td>
                </tr>
            )
        });

        const course = getProp(resource, "data.course", {});

        const messages = getProp(resource, "data.messages", []).map((item) => (
            <li>
                <p><a href="#" title="">@{item.user_fname} {item.user_lname}</a>{item.MessageTitle}</p>
                <p>{item.MessageContent}</p>
                <small>{item.CreateUpdateDate}</small>
            </li>
        ));

        const canEnroll = getProp(resource, "data.canEnroll", false);

        const isEnrolled = getProp(resource, "data.isEnrolled", false);

        const enrolledState = getProp(resource, "data.EnrolledState", 0);

        const recentLectures = getProp(resource, "data.lectures", []).map((item) => (
            <li>
                <div className="post">
                    <h4 className={"font-bold" + (canSeeLectures ? " link-like-element has-cursor" : "")}
                        onClick={() => canSeeLectures ? this.loadLecture(item.LectureID) : () => {
                        }}>{item.LectureName}</h4>
                    <small>{item.LectureShortDesc}</small>
                </div>
            </li>
        ));

        const completed = getProp(resource, "data.completed", false) == 1;
        const cert = getProp(resource, "data.cert", []);

        const results = getProp(resource, "data.results", {});
        
        const quizes = getProp(resource, "data.quizes", []).map((item, i) => {
            // const canTry = item.RetryRate === null && !completed && (!results[item.QuizID] || (results[item.QuizID].length < item.RetryRate));
            let canTry;
            if (item.RetryRate === null) {
                canTry = true;
            } else if (!completed && (!results[item.QuizID] || (results[item.QuizID].length < item.RetryRate))) {
                canTry = true;
            } else {
                canTry = false;
            }
            return (
                <tr key={i}>
                    <td className={"font-bold " + (canTry ? " link-like-element has-cursor" : "")}
                        onClick={() => !!canTry && this.toggleConfirmModal(() => {
                            this.props.history.push(process.env.PUBLIC_URL + "/quiz/start/" + item.QuizID);
                        })}
                    >{item.QuizName}</td>
                    <td>{item.QuizDesc}</td>
                    <td>{!!results[item.QuizID] && (
                        <span>
                        {results[item.QuizID].map((quiz) => (
                            <span>
                                {(!(+quiz.AutoRate) && !(+quiz.RatedByID)) ?
                                    <span>{translate("text.quiz_not_rated_yet")}</span>
                                    :
                                    <>
                                        {quiz.Score} {!!quiz.ScoreNumber && (<span>({quiz.ScoreNumber})</span>)}
                                    </>
                                }
                                <span> ({toFrontDateTime(quiz.EndTime)}) </span>
                                <br/>
                            </span>
                        ))}
                        </span>
                    )}</td>
                </tr>
            )
        });

        const lecture = getProp(secondResource, "data.lecture", {});

        const pages = getProp(secondResource, "data.pages", []).map((item, i) => {

            switch (item.LectureEntryType) {
                case "1":
                    return (
                        <React.Fragment key={i}>
                            <div dangerouslySetInnerHTML={{__html: item.LectureEntryContent}}>
                            </div>
                        </React.Fragment>
                    )
                case "2":
                    const fileType = item.LectureEntryContent.split(".").pop();
                    const isPpt = (fileType === 'pptx' || fileType === 'ppt');
                    const filePath = Env.getApiUrl('api/documents/preview', {
                        LecturePageID: item.LecturePageID,
                        token: !!LocalStorage.get('user') && LocalStorage.get('user').access_token
                    });
                    return (
                        <React.Fragment key={i}>
                            {!!item.CanDownload && (item.CanDownload != 0) && (
                                <button
                                    className={"btn btn-primary margin-bottom-10"}
                                    onClick={() => {
                                        this.props.dispatch(downloadDocument({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                LecturePageID: item.LecturePageID,
                                                name: "Lecture." + item.LectureEntryContent.split(".").pop()
                                            }
                                        }))
                                    }}
                                >{translate("text.download")}</button>
                            )}
                            {(isPpt) && (
                                <iframe
                                    src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(filePath) + ''}
                                    width='100%' height='600px' frameBorder='0'/>
                            )}
                            {!isPpt && (
                                <FileViewer
                                    fileType={fileType}
                                    filePath={filePath}
                                    onError={(e) => {
                                        console.log(e)
                                    }}/>
                            )}
                        </React.Fragment>
                    )
                case "3":
                    return (
                        <React.Fragment key={i}>
                            <ReactPlayer
                                className='react-player'
                                url={item.LectureEntryContent}
                                width='100%'
                                height='100%'
                                playing={true}
                                loop={false}
                                volume={1}
                                muted={false}
                                controls={true}
                            />
                        </React.Fragment>
                    )
            }
        });

        const pagesPreview = getProp(secondResource, "data.pages", []).map((item, i) => {

            let icon = '';
            switch (item.LectureEntryType) {
                case "1":
                    icon = (<span><i className="fa fa-file-text"></i></span>);
                    break;
                case "2":
                    const fileType = item.LectureEntryContent.split(".").pop();
                    icon = (<span><i className="fa fa-download"></i>{" (" + fileType + ")"}</span>);
                    break;
                case "3":
                    icon = (<span><i className="fa fa-video-camera"></i></span>);
                    break;
            }

            return (
                <div className={"page-preview-wrap has-cursor " + ((this.state.currentPage === i) ? "selected" : "")}
                     onClick={() => {
                         this.updateOffset(i, i + 1)
                         if (this.state.expandedLectureWindow) {
                             this.scrollToElm();
                         } else {
                             window.scrollTo({top: this.myRef.current.offsetTop, behavior: 'smooth'})
                         }
                     }}
                >
                    <span className={"num"}>{i + 1}</span>
                    <h5>{item.LectureEntryDescription}</h5>
                    {icon}
                </div>
            )
        });

        const forumThreads = getProp(this.props, "resource.data.forum", [])
            .map((item, i) => {
                return (
                    <tr key={i}>
                        <td className={"font-bold link-like-element has-cursor"}
                            onClick={() => this.loadForumThread(item.CourseForumThreadID)}>
                            {item.ForumThreadTitle}
                        </td>
                        <td>
                            {item.ForumThreadDesc}
                        </td>
                    </tr>
                );
            });

        const forumThreadData = getProp(secondResource, "data.forumThread", {});

        const forumThreadMessages = getProp(secondResource, "data.messages", []).map((item) => (
            <li>
                <p><a href="#" title="">@{item.user_fname} {item.user_lname}</a>{item.MessageTitle}</p>
                <p>{item.MessageContent}</p>
                <small>{item.CreateUpdateDate}</small>
            </li>
        ));

        return (
            <PublicLayout {...this.props}>
                <div className="page-title bgg">
                    <div className="container clearfix">
                        <div className="title-area pull-left">
                            <h2>{translate("text.course")}
                                <small>{course.CourseName}</small>
                            </h2>
                        </div>
                    </div>
                </div>

                <section className="section bgw small-top-pad">
                    <div className="container">
                        <div className="row">

                            {resource.create && !resource.isLoading && (
                                <div>
                                    <SuccessMessage className={"action-success-big"}
                                                    text={translate("label.entry_created")}
                                                    onDismiss={() => this.props.dispatch(resetResourceMessage())}
                                    />
                                </div>
                            )}

                            {this.state.forumThreadLoaded && (
                                <React.Fragment>
                                    <div
                                        className={"nav nav-pills nav-stacked col-md-9 col-sm-12 " + (this.state.expandedLectureWindow ? ("expanded-window-active") : "")}>

                                        <div className="btn btn-warning block margin-right-10"
                                             onClick={this.closeForum}><i
                                            className="fa fa-angle-double-left"></i> {translate("btn.back_to_forum")}
                                        </div>

                                        <div className="btn btn-info margin-right-10"
                                             onClick={this.toggleExpandLectureWindow}>
                                            {(this.state.expandedLectureWindow ? (translate("btn.collapse_forum_view")) : (translate("btn.expand_forum_view")))}
                                        </div>

                                        <div className="btn btn-info margin-right-10"
                                             onClick={() => this.toggleAddForumModal(null)}>
                                            {translate("btn.add_new_forum_message")}
                                        </div>

                                        <h3>{forumThreadData.ForumThreadTitle}</h3>
                                        <h5>{forumThreadData.ForumThreadDesc}</h5>

                                        <div className="margin-top-40">
                                            {forumThreadMessages}
                                        </div>

                                        <AddResourceDialog
                                            cancel={this.toggleAddForumModal}
                                            visible={this.state.addForumMessageModal}
                                            keyid={"CourseForumMessageID"}
                                            fields={this.state.forumMessageFields}
                                            {...this.props}
                                            id={forumThreadData.CourseForumThreadID}
                                            resourceName={Resources.CourseForumMessages}
                                            secondPiggyResource={Resources.CourseForumMessages}
                                        />
                                    </div>
                                </React.Fragment>
                            )}

                            {this.state.lectureLoaded && (
                                <React.Fragment>
                                    <h3 className='padding-left-15'>{lecture.LectureName}</h3>
                                    <div
                                        className={"padding-left-15 nav nav-pills nav-stacked col-md-" + (!this.state.lectureLoaded ? "9" : "12") + "col-sm-12 " + (this.state.expandedLectureWindow ? ("expanded-window-active") : "")}>
                                        <div>
                                            <div className="btn btn-default margin-right-10"
                                                 onClick={this.closeLecture}><i
                                                className="fa fa-angle-double-left"></i> {translate("btn.back_to_lectures")}
                                            </div>

                                            {(currLectNum > 0) && (
                                                <div className="btn btn-default margin-right-10"
                                                     onClick={() => this.loadLecture(dateLectures[currLectNum - 1].LectureID)}>
                                                    <i className="fa fa-angle-left"></i> {translate("btn.prev_lecture")}
                                                </div>
                                            )}
                                            <div className="btn btn-default margin-right-10"
                                                 onClick={this.toggleExpandLectureWindow}>
                                                {(this.state.expandedLectureWindow ? (translate("btn.collapse_lecture_view")) : (translate("btn.expand_lecture_view")))}
                                            </div>

                                            {(currLectNum < dateLectures.length - 1) && (
                                                <div className="btn btn-default"
                                                     onClick={() => this.loadLecture(dateLectures[currLectNum + 1].LectureID)}>
                                                    <i className="fa fa-angle-right"></i> {translate("btn.next_lecture")}
                                                </div>
                                            )}
                                        </div>

                                        <div className={"margin-top-25"}>
                                            <div className={"page-preview inline-block vertical-top test"}>
                                                {pagesPreview}
                                            </div>
                                            <div ref={this.myRef} className={"page inline-block vertical-top width-100 custom-page-curse"}>
                                                {pages[this.state.currentPage]}
                                            </div>
                                        </div>

                                        {resource.isLoading && (
                                            <LoaderSmall addClass={"text-center"}/>
                                        )}
                                    </div>
                                </React.Fragment>
                            )}

                            {!this.state.lectureLoaded && !this.state.forumThreadLoaded && (
                                <div className="tabbable customtab">
                                    <ul className="nav nav-pills nav-stacked col-md-3 col-sm-12">
                                        <li className={(this.state.loadedTab === TAB_INFO_ID) ? "active has-cursor" : "has-cursor"}>
                                            <a
                                                onClick={() => this.switchTab(TAB_INFO_ID)}><i
                                                className="fa fa-home"></i> {translate("text.dashboard")}</a></li>
                                        <li className={(this.state.loadedTab === TAB_LECTURES_ID) ? "active has-cursor" : "has-cursor"}>
                                            <a
                                                onClick={() => this.switchTab(TAB_LECTURES_ID)}><i
                                                className="fa fa-graduation-cap"></i> {translate("text.lectures")}</a>
                                        </li>
                                        {isEnrolled && (
                                            <React.Fragment>
                                                <li className={(this.state.loadedTab === TAB_QUIZ_ID) ? "active has-cursor" : "has-cursor"}>
                                                    <a
                                                        onClick={() => this.switchTab(TAB_QUIZ_ID)}><i
                                                        className="fa fa-university"></i> {translate("text.quiz")}</a>
                                                </li>
                                                <li className={(this.state.loadedTab === TAB_FORUM_ID) ? "active has-cursor" : "has-cursor"}>
                                                    <a
                                                        onClick={() => this.switchTab(TAB_FORUM_ID)}><i
                                                        className="fa fa-ticket"></i> {translate("text.forum")}</a></li>

                                                {(!!course.CanDownloadCert && course.CanDownloadCert != 0 && !!completed && cert.length > 0) && (
                                                    <li className={(this.state.loadedTab === TAB_CERT_ID) ? "active has-cursor" : "has-cursor"}>
                                                        <a
                                                            onClick={() => this.switchTab(TAB_CERT_ID)}><i
                                                            className="fa fa-certificate"></i> {translate("text.certificate")}
                                                        </a>
                                                    </li>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </ul>
                                    <div className="tab-content col-md-6 col-sm-12">
                                        {(this.state.loadedTab === 1) && (
                                            <div className="tab-pane active" id="a">
                                                <div className="clearfix"
                                                     dangerouslySetInnerHTML={{__html: course.CourseLongDesc}}>
                                                </div>
                                            </div>
                                        )}
                                        {(this.state.loadedTab === 2) && (
                                            <div className="tab-pane active" id="b">
                                                <div className="course-table clearfix">
                                                    <div className="big-title">
                                                        <h2 className="related-title">
                                                            <span>{translate("text.lectures")}</span>
                                                        </h2>
                                                    </div>
                                                    <table className="table-leactures-front">
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>{translate("text.lecture_title")}</th>
                                                            <th>{translate("text.lecture_desc")}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {lectures}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                        {(this.state.loadedTab === 3) && (
                                            <div className="tab-pane active" id="c">

                                                <div className="big-title">
                                                    <h2 className="related-title">
                                                        <span>{translate("text.quizes")}</span>
                                                    </h2>
                                                </div>
                                                <table className="table-leactures-front">
                                                    <thead>
                                                    <tr>
                                                        <th>{translate("text.quiz_title")}</th>
                                                        <th>{translate("text.quiz_desc")}</th>
                                                        <th>{translate("text.quiz_result")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {quizes}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {(this.state.loadedTab === 4) && (
                                            <React.Fragment>
                                                <div className="tab-pane active" id="d">

                                                    <div className="big-title">
                                                        <h2 className="related-title">
                                                            <span>{translate("text.forum")}</span>
                                                        </h2>
                                                    </div>
                                                    <table className="table-leactures-front">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                {translate("table.forum_thread_name")}
                                                            </th>
                                                            <th>
                                                                {translate("table.description")}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {forumThreads}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </React.Fragment>
                                        )}
                                        <div className="single-content">
                                            {resource.isLoading && (
                                                <LoaderSmall addClass={"text-center"}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!this.state.lectureLoaded && (
                                <div id="sidebar" className="col-md-3 col-sm-12">
                                    {!completed && !isEnrolled && canEnroll && (!enrolledState) && (
                                        <div className="widget custom-widget clearfix">
                                            <div className="customwidget text-left w40">
                                                <form>
                                                    <div onClick={this.enroll}
                                                         className="btn btn-primary btn-block">{translate("text.enroll")}</div>
                                                </form>
                                            </div>
                                        </div>
                                    )}

                                    {!completed && (enrolledState == 1) && (
                                        <div className="widget custom-widget clearfix">
                                            <div className="customwidget text-left w40">
                                                <h3 className="widget-title">{translate("text.enrolled_awaiting")}</h3>
                                            </div>
                                        </div>
                                    )}

                                    {!completed && isEnrolled && (
                                        <div className="widget custom-widget clearfix">
                                            <div className="customwidget text-left w40">
                                                <h3 className="widget-title">{translate("text.enrolled")}</h3>
                                            </div>
                                        </div>
                                    )}

                                    {completed && (
                                        <div className="widget custom-widget clearfix">
                                            <div className="customwidget text-left w40">
                                                <h3 className="widget-title">{translate("text.course_completed")}</h3>
                                            </div>
                                        </div>
                                    )}

                                    <div className="widget clearfix p-3">
                                        <div className="big-title">
                                            <h2 className="related-title">
                                                <span>{translate("text.recent_messages")}</span>
                                            </h2>
                                        </div>

                                        <div className="twitter-widget">
                                            <ul className="latest-tweets">
                                                {messages}
                                                {resource.isLoading && (
                                                    <LoaderSmall addClass={"text-center"}/>
                                                )}
                                                {(!resource.isLoading && (messages.length === 0)) && (
                                                    <span>{translate("text.no_items")}</span>
                                                )}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="widget clearfix">
                                        <div className="big-title">
                                            <h2 className="related-title">
                                                <span>{translate("text.recent_lectures")}</span>
                                            </h2>
                                        </div>

                                        <div className="postpager liststylepost">
                                            <ul className="pager">
                                                {recentLectures}
                                                {resource.isLoading && (
                                                    <LoaderSmall addClass={"text-center"}/>
                                                )}
                                                {(!resource.isLoading && (messages.length === 0)) && (
                                                    <span>{translate("text.no_items")}</span>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                <ConfirmQuizDialog
                    text={this.props.translate("message.are_you_sure_start_quiz")}
                    submit={this.state.confirmModal}
                    cancel={this.toggleConfirmModal}
                    visible={this.state.confirmModal}
                    {...this.props}
                />
            </PublicLayout>
        );
    }
}

export default connect(state => state)(HomeView);
