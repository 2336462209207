import React, {Component} from "react";
import {
    createResource,
    deleteResource,
    getResource,
    resetResourceMessage,
    updateResource
} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import PrivateLayout from "../../components/private-layout";
import {checkPerm, CREATE_PERM, DELETE_PERM, fieldsToHtml, getProp, toFrontDate, UPDATE_PERM} from "../../util/util";
import {connect} from "react-redux";
import {Field, FieldsManager} from "../../data/services/fields";
import Button from "../../components/button";
import LoaderSmall from "../../components/loader-small";
import SuccessMessage from "../../components/success-message";
import {Link} from "react-router-dom";
import AddResourceDialog from "../../components/dialog/add-resource";
import ConfirmDialog from "../../components/dialog/confirm-dialog";
import NoResults from "../../components/no-results";
import AddQuizDialog from "../../components/dialog/add-quiz-dialog";
import Dropzone from "react-dropzone";
import Env from "../../util/env";
import InviteUserDialog from "../../components/dialog/invite-user-dialog";

class CreateCourseView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Shared
            activeTab: 1,
            deleteModal: false,
            // Info
            canUpdate: false,
            fields: this.getDefaultFields(),
            // Lectures
            lectureFields: {
                'LectureName': new Field('LectureName', '', ['empty'], false),
                'LectureShortDesc': new Field('LectureShortDesc', '', ['empty'], false),
                'LectureLongDesc': new Field('LectureLongDesc', '', ['empty'], false, 'tiny'),
                'LectureActive': new Field('LectureActive', '', [], false, 'checkbox'),
                'LectureRequired': new Field('LectureRequired', '', [], false, 'checkbox'),
                'LectureStartDate': new Field('LectureStartDate', '', [], false, 'datetime')
            },
            addLectureModal: false,
            selectedLectureItem: null,
            // Message
            messageFields: {
                'MessageDate': new Field('MessageDate', '', [], false, 'datetime'),
                'MessageTitle': new Field('MessageTitle', '', ['empty'], false),
                'MessageContent': new Field('MessageContent', '', ['empty'], false)
            },
            addMessageModal: false,
            selectedMessageItem: null,
            // Quiz
            quizFields: {
                'QuizName': new Field('QuizName', '', ['empty'], false),
                'QuizDesc': new Field('QuizDesc', '', ['empty'], false),
                'QuizActive': new Field('QuizActive', '', [], false, 'checkbox'),
                'QuizStartDate': new Field('QuizStartDate', '', [], false, 'datetime'),
                'AutoRate': new Field('AutoRate', '', [], false, 'checkbox'),
                'PassRate': new Field('PassRate', '', [], false, 'select'),
                'RetryRate': new Field('RetryRate', '', [], false, 'select'),
                'MaxQuestions': new Field('MaxQuestions', '', ['integer'], false,),
                'TimeLimit': new Field('TimeLimit', '', [], false, "range",)
            },
            selectedQuizItem: null,
            addQuizModal: false,
            // User
            addUserModal: false,
            approveModal: false,
            // Forum
            forumFields: {
                'ForumThreadTitle': new Field('ForumThreadTitle', '', ['empty'], false),
                'ForumThreadDesc': new Field('ForumThreadDesc', '', ['empty'], false),
            },
            addForumModal: false,
            selectedForumItem: null,
            // Cert
            certFields: {
                'CertName': new Field('CertName', '', ['empty'], false),
                'CertContent': new Field('CertContent', '', ['empty'], false, 'tiny'),
            },
            selectedCertItem: null,
            addCertModal: false,
            // Files
            files: []
        };
    }

    componentDidMount() {
        const id = this.getId();
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: id
            },
            resource: Resources.Course
        }));

        if (LocalStorage.has('edit_tab_' + this.getId())) {
            this.setState({activeTab: LocalStorage.get('edit_tab_' + this.getId())});
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getDefaultFields()
            });
        }
    }

    getDefaultFields = () => {
        const fieldTemplates = {
            'CourseName': new Field('CourseName', '', ['empty'], false),
            'CourseShortDesc': new Field('CourseShortDesc', '', ['empty'], false),
            'CoursePromoText': new Field('CoursePromoText', '', [], false),
            'CourseConfCertLevel': new Field('CourseConfCertLevel', '', ['empty'], false, 'select'),
            'CourseConfPublicLevel': new Field('CourseConfPublicLevel', '', ['empty'], false, 'select'),
            'CourseConfEnrollLevel': new Field('CourseConfEnrollLevel', '', ['empty'], false, 'select'),
            'CourseCategoryID': new Field('CourseCategoryID', '', [''], false, 'select'),
            'CourseActive': new Field('CourseActive', '', [], false, 'checkbox'),
            'CoursePromo': new Field('CoursePromo', '', [], false, 'checkbox'),
            'CourseLongDesc': new Field('CourseLongDesc', '', ['empty'], false, 'tiny'),
        };

        const values = getProp(this.props, "resource.data.course", {});

        const id = this.getId();
        const formats = ['float', 'float_or_empty', 'integer', 'integer_or_empty'];
        return Object.values(fieldTemplates).reduce((memo, item) => {
            if (!!id) {
                if (item.validate.filter(value => formats.includes(value)).length > 0) {
                    item.value = values[item.name];
                } else {
                    item.value = values[item.name];
                }
            }
            memo[item.name] = item;

            return memo;
        }, {});
    };

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    handleInputChange = (name, value) => {
        let update = FieldsManager.updateField(this.state.fields, name, value);
        this.setState({fields: update, canUpdate: true});
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.getId()) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({id: this.getId()}, FieldsManager.getFieldKeyValues(this.state.fields)),
                        resource: Resources.Course,
                        query: {
                            id: this.getId()
                        },
                        files: this.state.files,
                        piggyResource: Resources.Course
                    }))
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields)),
                        files: this.state.files,
                        resource: Resources.Course
                    }))
                }

                this.setState({
                    files: [],
                    canUpdate: false
                });
            }
        })
    };

    cancel = () => {
        this.setState({fields: this.getDefaultFields(), files: [], canUpdate: false});
    };

    toggleAddLectureModal = (item) => {
        this.setState({
            selectedLectureItem: item,
            addLectureModal: !this.state.addLectureModal
        })
    };

    toggleAddMessageModal = (item) => {
        this.setState({
            selectedMessageItem: item,
            addMessageModal: !this.state.addMessageModal
        })
    };

    toggleAddQuizModal = (item) => {
        this.setState({
            selectedQuizItem: item,
            addQuizModal: !this.state.addQuizModal
        })
    };

    toggleAddUserModal = (item) => {
        this.setState({
            selectedMessageItem: item,
            addUserModal: !this.state.addUserModal
        })
    };

    toggleAddTeacherModal = () => {
        this.setState({
            addTeacherModal: !this.state.addTeacherModal
        })
    };

    toggleAddCertModal = (item) => {
        this.setState({
            selectedCertItem: item,
            addCertModal: !this.state.addCertModal
        })
    };

    toggleAddForumModal = (item) => {
        this.setState({
            selectedForumItem: item,
            addForumModal: !this.state.addForumModal
        })
    };

    toggleDeleteResourceModal = (submit) => {
        this.setState({
            deleteModal: submit
        })
    };

    toggleApproveResourceModal = (submit) => {
        this.setState({
            approveModal: submit
        })
    };

    switchTab = (tab) => {
        LocalStorage.set('edit_tab_' + this.getId(), tab);
        this.setState({activeTab: tab});
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    };

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    onDrop = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    render() {
        const {resource, translate} = this.props;

        const cats = getProp(this.props, "resource.data.categories", []);

        const lectures = getProp(this.props, "resource.data.lectures", []).map((item) => {
            return (
                <tr>
                    <td>
                        {item.LectureName}
                    </td>
                    <td>
                        {item.LectureShortDesc}
                    </td>
                    <td>
                        {checkPerm(Resources.CoursesLectures, UPDATE_PERM) && (
                            <div className={"btn btn-default btn-sm margin-right-10"}
                                 title={this.props.translate("btn.update")}
                                 onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/courses/lecture/" + item.LectureID)}
                            >
                                {this.props.translate("btn.lecture_pages")}
                            </div>
                        )}

                        {checkPerm(Resources.CoursesLectures, UPDATE_PERM) && (
                            <div className={"btn btn-primary btn-sm margin-right-10"}
                                 title={this.props.translate("btn.update")}
                                 onClick={() => this.toggleAddLectureModal(item)}
                            >
                                {this.props.translate("btn.update")}
                            </div>
                        )}

                        {checkPerm(Resources.CoursesLectures, DELETE_PERM) && (
                            <div
                                className="btn btn-danger btn-sm"
                                title={this.props.translate("btn.delete")}
                                onClick={() => this.toggleDeleteResourceModal(() => {
                                    this.props.dispatch(deleteResource({
                                        user: LocalStorage.get('user'),
                                        query: {
                                            LectureID: item.LectureID,
                                            id: this.getId()
                                        },
                                        id: this.getId(),
                                        resource: Resources.CoursesLectures,
                                        piggyResource: Resources.Course
                                    }));
                                    this.toggleDeleteResourceModal(null);
                                })}>
                                {this.props.translate("btn.delete")}
                            </div>
                        )}

                    </td>
                </tr>

            )
        });

        const messages = getProp(this.props, "resource.data.messages", [])
            .map((item) => {
                return (
                    <tr>
                        <td>
                            {toFrontDate(item.CreateUpdateDate)}
                        </td>
                        <td>
                            {item.MessageTitle}
                        </td>
                        <td>
                            {item.MessageContent}
                        </td>
                        <td>
                            {checkPerm(Resources.CoursesMessages, DELETE_PERM) && (
                                <div
                                    className="btn btn-danger btn-sm"
                                    title={translate("btn.delete")}
                                    onClick={() => this.toggleDeleteResourceModal(() => {
                                        this.props.dispatch(deleteResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                CourseMessageID: item.CourseMessageID,
                                                id: this.getId()
                                            },
                                            id: this.getId(),
                                            resource: Resources.CoursesMessages,
                                            piggyResource: Resources.Course
                                        }));
                                        this.toggleDeleteResourceModal(null);
                                    })}>
                                    {translate("btn.delete")}
                                </div>
                            )}
                        </td>
                    </tr>
                );
            });

        const quiz = getProp(this.props, "resource.data.quiz", [])
            .map((item) => {
                return (
                    <tr>
                        <td>
                            {item.QuizName}
                        </td>
                        <td>
                            {item.QuizDesc}
                        </td>
                        <td>
                            {(!!item.QuizActive && item.QuizActive != 0) ? translate("text.yes") : translate("text.no")}
                        </td>
                        <td>
                            {checkPerm(Resources.CourseQuiz, UPDATE_PERM) && (
                                <div className={"btn btn-default btn-sm margin-right-10"}
                                     title={this.props.translate("btn.quiz_pages")}
                                     onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/quizes/" + item.QuizID + "/" + this.getId())}
                                >
                                    {this.props.translate("btn.quiz_pages")}
                                </div>
                            )}

                            {checkPerm(Resources.CourseQuiz, UPDATE_PERM) && (
                                <div className={"btn btn-primary btn-sm margin-right-10"}
                                     title={this.props.translate("btn.update")}
                                     onClick={() => this.toggleAddQuizModal(item)}
                                >
                                    {this.props.translate("btn.update")}
                                </div>
                            )}

                            {checkPerm(Resources.CourseQuiz, DELETE_PERM) && (
                                <div
                                    className="btn btn-danger btn-sm"
                                    title={this.props.translate("btn.delete")}
                                    onClick={() => this.toggleDeleteResourceModal(() => {
                                        this.props.dispatch(deleteResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                QuizID: item.QuizID,
                                                id: this.getId()
                                            },
                                            id: this.getId(),
                                            resource: Resources.CourseQuiz,
                                            piggyResource: Resources.Course
                                        }));
                                        this.toggleDeleteResourceModal(null);
                                    })}>
                                    {this.props.translate("btn.delete")}
                                </div>
                            )}

                            <div className={"btn btn-default btn-sm margin-left-10"}
                                 title={this.props.translate("btn.quiz_results")}
                                 onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/quizes/results/" + item.QuizID + "/" + this.getId())}
                            >
                                {this.props.translate("btn.quiz_results")}
                            </div>
                        </td>
                    </tr>
                );
            });

        const users = getProp(this.props, "resource.data.users", [])
            .map((item) => {
                return (
                    <tr>
                        <td>
                            {item.user_fname}
                        </td>
                        <td>
                            {item.user_lname}
                        </td>
                        <td>
                            {item.user_email}
                        </td>
                        <td>
                            {item.CreateUpdateDate}
                        </td>
                        <td>
                            {!!item.Completed ? translate("text.yes") : translate("text.no")}
                        </td>
                        <td>
                            {(item.EnrolledState == 1) ? translate("text.enroll_wait") : translate("text.full_enroll")}
                        </td>
                        <td>
                            {(item.EnrolledState == 1) && checkPerm(Resources.CourseInvite, DELETE_PERM) && (
                                <div
                                    className="btn btn-success btn-sm margin-right-10"
                                    title={this.props.translate("btn.enroll_approve")}
                                    onClick={() => this.toggleApproveResourceModal(() => {
                                        this.props.dispatch(updateResource({
                                            user: LocalStorage.get('user'),
                                            params: {
                                                UserID: item.user_id,
                                                CourseID: this.getId(),
                                                id: this.getId()
                                            },
                                            query: {
                                                id: this.getId()
                                            },
                                            id: this.getId(),
                                            resource: Resources.CourseInvite,
                                            piggyResource: Resources.Course
                                        }));
                                        this.toggleApproveResourceModal(null);
                                    })}>
                                    {this.props.translate("btn.enroll_approve")}
                                </div>
                            )}

                            {checkPerm(Resources.CourseUser, DELETE_PERM) && (
                                <div
                                    className="btn btn-danger btn-sm"
                                    title={this.props.translate("btn.delete")}
                                    onClick={() => this.toggleDeleteResourceModal(() => {
                                        this.props.dispatch(deleteResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                UserID: item.user_id,
                                                CourseID: this.getId(),
                                                id: this.getId()
                                            },
                                            id: this.getId(),
                                            resource: Resources.CourseUser,
                                            piggyResource: Resources.Course
                                        }));
                                        this.toggleDeleteResourceModal(null);
                                    })}>
                                    {this.props.translate("btn.delete")}
                                </div>
                            )}
                        </td>
                    </tr>
                );
            });

        const teachers = getProp(this.props, "resource.data.teachers", [])
            .map((item) => {
                return (
                    <tr>
                        <td>
                            {item.user_fname}
                        </td>
                        <td>
                            {item.user_lname}
                        </td>
                        <td>
                            {item.user_email}
                        </td>
                        <td>
                            {checkPerm(Resources.CourseQuiz, DELETE_PERM) && (
                                <div
                                    className="btn btn-danger btn-sm"
                                    title={this.props.translate("btn.delete")}
                                    onClick={() => this.toggleDeleteResourceModal(() => {
                                        this.props.dispatch(deleteResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                TeacherInCourseID: item.TeacherInCourseID,
                                                id: this.getId()
                                            },
                                            id: this.getId(),
                                            resource: Resources.CourseTeacher,
                                            piggyResource: Resources.Course
                                        }));
                                        this.toggleDeleteResourceModal(null);
                                    })}>
                                    {this.props.translate("btn.delete")}
                                </div>
                            )}
                        </td>
                    </tr>
                );
            });

        const certs = getProp(this.props, "resource.data.cert", [])
            .map((item, i) => {
                return (
                    <tr key={i}>
                        <td>
                            {item.CertName}
                        </td>
                        <td>

                            {checkPerm(Resources.CourseAdminCert, UPDATE_PERM) && (
                                <div className={"btn btn-primary btn-sm margin-right-10"}
                                     title={this.props.translate("btn.update")}
                                     onClick={() => this.toggleAddCertModal(item)}
                                >
                                    {this.props.translate("btn.update")}
                                </div>
                            )}

                            {checkPerm(Resources.CourseAdminCert, DELETE_PERM) && (
                                <div
                                    className="btn btn-danger btn-sm"
                                    title={this.props.translate("btn.delete")}
                                    onClick={() => this.toggleDeleteResourceModal(() => {
                                        this.props.dispatch(deleteResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                CertID: item.CertID,
                                                id: this.getId()
                                            },
                                            id: this.getId(),
                                            resource: Resources.CourseAdminCert,
                                            piggyResource: Resources.Course
                                        }));
                                        this.toggleDeleteResourceModal(null);
                                    })}>
                                    {this.props.translate("btn.delete")}
                                </div>
                            )}
                        </td>
                    </tr>
                );
            });

        const forumThreads = getProp(this.props, "resource.data.forum", [])
            .map((item, i) => {
                return (
                    <tr key={i}>
                        <td>
                            {item.ForumThreadTitle}
                        </td>
                        <td>
                            {item.ForumThreadDesc}
                        </td>
                        <td>
                            {item.CreateUpdateDate}
                        </td>
                        <td>
                            {checkPerm(Resources.CourseForum, UPDATE_PERM) && (
                                <div className={"btn btn-default btn-sm margin-right-10"}
                                     title={this.props.translate("btn.thread_messages")}
                                     onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/forum-messages/" + item.CourseForumThreadID)}
                                >
                                    {this.props.translate("btn.thread_messages")}
                                </div>
                            )}

                            {checkPerm(Resources.CourseForum, UPDATE_PERM) && (
                                <div className={"btn btn-primary btn-sm margin-right-10"}
                                     title={this.props.translate("btn.update")}
                                     onClick={() => this.toggleAddForumModal(item)}
                                >
                                    {this.props.translate("btn.update")}
                                </div>
                            )}

                            {checkPerm(Resources.CourseForum, DELETE_PERM) && (
                                <div
                                    className="btn btn-danger btn-sm"
                                    title={this.props.translate("btn.delete")}
                                    onClick={() => this.toggleDeleteResourceModal(() => {
                                        this.props.dispatch(deleteResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                CourseForumThreadID: item.CourseForumThreadID,
                                                id: this.getId()
                                            },
                                            id: this.getId(),
                                            resource: Resources.CourseForum,
                                            piggyResource: Resources.Course
                                        }));
                                        this.toggleDeleteResourceModal(null);
                                    })}>
                                    {this.props.translate("btn.delete")}
                                </div>
                            )}
                        </td>
                    </tr>
                );
            });

        const files = this.state.files.map((item, i) => {
            return (
                <div className="up-shared-doc">
                    <span>
                    {item.name}
                    </span>
                </div>
            )
        });

        let fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, false, {
            CourseConfPublicLevel: {
                1: translate("text.visible_for_all_(lectures_hidden)"),
                2: translate("text.visible_for_all_(including_lectures)"),

                3: translate("text.visible_for_logged_users_(lectures_hidden)"),
                4: translate("text.visible_for_logged_users_(including_lectures)")
            },
            CourseConfEnrollLevel: {
                1: translate("text.free_for_all"),
                2: translate("text.invite_only"),
                3: translate("text.approve_and_invites")
            },
            CourseConfCertLevel: {
                "-1": translate("text.no_cert_in_course"),
                1: translate("text.always_download"),
                2: translate("text.after_lectures"),
                3: translate("text.after_quiz")
            },
            CourseCategoryID: cats.reduce((memo, it) => {
                memo[it.CourseCategoryID] = it.CourseCategoryName;
                return memo;
            }, {})
        });

        fieldsHtml = [
            fieldsHtml.slice(0, 4),
            fieldsHtml.slice(4, 7),
            fieldsHtml.slice(7, 10)
        ];

        const editing = !!this.getId();

        const course = getProp(this.props, "resource.data.course", {});

        return (
            <PrivateLayout {...this.props} menuActiveResource={Resources.AdminDashboard}>
                <div className="big-title">
                    <h2 className="related-title">
                        <span>{!editing ? translate("txt.create_course") : (translate("txt.edit_course") + " - " + (this.state.fields.CourseName.value ? this.state.fields.CourseName.value : ""))}</span>
                    </h2>
                </div>

                <div>
                    {!!editing && (
                        <div className={"tabs-wrap"}>
                            <div
                                onClick={() => this.switchTab(1)}
                                className={"tab-edit has-cursor" + ((this.state.activeTab === 1) ? " active" : "")}>
                                {translate("txt.info")}
                            </div>
                            <div
                                onClick={() => this.switchTab(2)}
                                className={"tab-edit has-cursor" + ((this.state.activeTab === 2) ? " active" : "")}>
                                {translate("txt.lectures")}
                            </div>
                            <div
                                onClick={() => this.switchTab(3)}
                                className={"tab-edit has-cursor" + ((this.state.activeTab === 3) ? " active" : "")}>
                                {translate("txt.messages")}
                            </div>
                            <div
                                onClick={() => this.switchTab(4)}
                                className={"tab-edit has-cursor" + ((this.state.activeTab === 4) ? " active" : "")}>
                                {translate("txt.quiz")}
                            </div>
                            <div
                                onClick={() => this.switchTab(7)}
                                className={"tab-edit has-cursor" + ((this.state.activeTab === 7) ? " active" : "")}>
                                {translate("text.teachers")}
                            </div>
                            <div
                                onClick={() => this.switchTab(5)}
                                className={"tab-edit has-cursor" + ((this.state.activeTab === 5) ? " active" : "")}>
                                {translate("txt.users")}
                            </div>
                            <div
                                onClick={() => this.switchTab(6)}
                                className={"tab-edit has-cursor" + ((this.state.activeTab === 6) ? " active" : "")}>
                                {translate("text.forum")}
                            </div>
                            <div
                                onClick={() => this.switchTab(8)}
                                className={"tab-edit has-cursor" + ((this.state.activeTab === 8) ? " active" : "")}>
                                {translate("text.cert")}
                            </div>
                        </div>
                    )}

                    {resource.create && !resource.isLoading && (
                        <div>
                            <SuccessMessage className={"action-success-big"} text={translate("label.entry_created")}
                                            onDismiss={() => this.props.dispatch(resetResourceMessage())}
                            />

                            {!editing && (
                                <React.Fragment>
                                    <Link
                                        className={"btn btn-primary margin-bottom-10"}
                                        to={`${process.env.PUBLIC_URL}/courses/` + resource.create.id}>{translate("btn.go_to_course")}
                                    </Link>

                                    <Button
                                        className={"btn btn-secondary"}
                                        onClick={() => this.props.dispatch(resetResourceMessage())}
                                        text={translate("btn.create_another")}/>
                                </React.Fragment>
                            )}
                        </div>
                    )}

                    {resource.update && !resource.isLoading && (
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_updated")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                    )}

                    {resource.deleteResource && !resource.isLoading && (
                        <SuccessMessage className={"action-success-big"} text={translate("label.entry_deleted")}
                                        onDismiss={() => this.props.dispatch(resetResourceMessage())}/>
                    )}

                    {resource.isLoading && (
                        <LoaderSmall addClass={"text-center"}/>
                    )}

                    {!resource.create && !resource.isLoading && (this.state.activeTab === 1) && (
                        <form onKeyPress={this.submitForm}>
                            <div className="form-element-container-half align-vertical-top">
                                {fieldsHtml[0]}
                            </div>

                            <div className="form-element-container-half align-vertical-top">
                                {fieldsHtml[1]}
                            </div>

                            <div className="form-element-container">
                                {fieldsHtml[2]}
                            </div>

                            <div className="form-element-container-half align-vertical-top image-admin-course">
                                <Dropzone onDrop={(acceptedFiles) => {
                                    this.setState({
                                        files: this.state.files.concat(acceptedFiles),
                                        canUpdate: true
                                    });
                                }}
                                          onDragEnter={this.onDragEnter}
                                          onDragLeave={this.onDragLeave}
                                          onDropAccepted={this.onDrop}
                                          multiple={false}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}
                                                 className={"dropzone " + (this.state.dropzoneActive ? "active" : "")}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                {files}
                            </div>

                            <div className="form-element-container-half align-vertical-top image-admin-course">
                                {!!course.CourseConfImagePath && (
                                    <div>
                                        <img src={Env.getApiUrl("" + course.CourseConfImagePath)} alt=""
                                             className="img-responsive"/>
                                    </div>
                                )}
                            </div>

                            <div className="form-buttons-container">
                                <Button
                                    className={"btn btn-success margin-right-10" + (!this.state.canUpdate ? " disabled" : "")}
                                    onClick={this.state.canUpdate ? this.submit : () => {
                                    }}
                                    text={translate(!!editing ? "btn.update" : "btn.create")}
                                />

                                {!!editing && (
                                    <Button
                                        className={"btn btn-warning" + (!this.state.canUpdate ? " disabled" : "")}
                                        onClick={this.state.canUpdate ? this.cancel : () => {
                                        }}
                                        text={translate("btn.undo")}
                                    />
                                )}
                            </div>
                        </form>
                    )}

                    {!resource.isLoading && (this.state.activeTab === 2) && (
                        <React.Fragment>
                            <div className="page-reports-actions">
                                <div className="page-search-wrapper">
                                    {checkPerm(Resources.CoursesLectures, CREATE_PERM) && (
                                        <button className="btn btn-primary margin-top-20"
                                                onClick={() => this.toggleAddLectureModal(null)}
                                        >{translate("btn.add_item")}
                                        </button>
                                    )}
                                </div>
                            </div>

                            <table>
                                <thead>
                                <tr>
                                    <th>{translate("table.lection_name")}</th>
                                    <th>{translate("table.short_description")}</th>
                                    <th>{translate("table.actions")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {lectures}
                                </tbody>
                            </table>

                            <AddResourceDialog
                                cancel={this.toggleAddLectureModal}
                                value={this.state.selectedLectureItem}
                                visible={this.state.addLectureModal}
                                keyid={"LectureID"}
                                fields={this.state.lectureFields}
                                resourceName={Resources.CoursesLectures}
                                piggyResourceName={Resources.Course}
                                {...this.props}
                                id={this.getId()}
                            />
                        </React.Fragment>
                    )}

                    {!resource.isLoading && (this.state.activeTab === 3) && (
                        <React.Fragment>
                            <div className="page-reports-actions">
                                <div className="page-search-wrapper">
                                    {checkPerm(Resources.CoursesMessages, CREATE_PERM) && (
                                        <button className="btn btn-primary margin-top-20"
                                                onClick={() => this.toggleAddMessageModal(null)}
                                        >{translate("btn.add_item")}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="sticky-table">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            {translate("field.MessageDate")}
                                        </th>
                                        <th>
                                            {translate("field.MessageTitle")}
                                        </th>
                                        <th>
                                            {translate("field.MessageContent")}
                                        </th>
                                        {checkPerm(Resources.CoursesMessages, UPDATE_PERM) && (
                                            <th>{translate("table.actions")}</th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {messages}
                                    </tbody>
                                </table>

                                {!resource.isLoading && (messages.length === 0) && (
                                    <NoResults text={translate("text.no_result")}/>
                                )}
                            </div>
                            <AddResourceDialog
                                cancel={this.toggleAddMessageModal}
                                value={this.state.selectedMessageItem}
                                visible={this.state.addMessageModal}
                                keyid={"MessageID"}
                                fields={this.state.messageFields}
                                resourceName={Resources.CoursesMessages}
                                piggyResourceName={Resources.Course}
                                {...this.props}
                                id={this.getId()}
                            />
                        </React.Fragment>
                    )}

                    {!resource.isLoading && (this.state.activeTab === 4) && (
                        <React.Fragment>
                            <div className="page-reports-actions">
                                <div className="page-search-wrapper">
                                    {checkPerm(Resources.CourseQuiz, CREATE_PERM) && (
                                        <button className="btn btn-primary margin-top-20"
                                                onClick={() => this.toggleAddQuizModal(null)}
                                        >{translate("btn.add_item")}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="sticky-table">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            {translate("field.QuizName")}
                                        </th>
                                        <th>
                                            {translate("field.QuizDesc")}
                                        </th>
                                        <th>
                                            {translate("field.QuizActive")}
                                        </th>
                                        {checkPerm(Resources.CourseQuiz, UPDATE_PERM) && (
                                            <th>{translate("table.actions")}</th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {quiz}
                                    </tbody>
                                </table>

                                {!resource.isLoading && (quiz.length === 0) && (
                                    <NoResults text={translate("text.no_result")}/>
                                )}
                            </div>
                            <AddQuizDialog
                                cancel={this.toggleAddQuizModal}
                                value={this.state.selectedQuizItem}
                                visible={this.state.addQuizModal}
                                keyid={"QuizID"}
                                fields={this.state.quizFields}
                                resourceName={Resources.CourseQuiz}
                                piggyResourceName={Resources.Course}
                                {...this.props}
                                id={this.getId()}
                                selects={{
                                    RetryRate: {
                                        0: translate("text.unlimited"),
                                        1: 1,
                                        2: 2,
                                        3: 3,
                                        4: 4,
                                        5: 5,
                                        6: 6,
                                        7: 7,
                                        8: 8,
                                        9: 9,
                                        10: 10,
                                    },
                                    PassRate: {
                                        1: "10%",
                                        2: "20%",
                                        3: "30%",
                                        4: "40%",
                                        5: "50%",
                                        6: "60%",
                                        7: "70%",
                                        8: "80%",
                                        9: "90%",
                                        10: "100%",
                                    },
                                    TimeLimit: {
                                        min: "0",
                                        max: "240"
                                    },
                                }}
                            />
                        </React.Fragment>
                    )}

                    {!resource.isLoading && (this.state.activeTab === 7) && (
                        <React.Fragment>
                            <div className="page-reports-actions">
                                <div className="page-search-wrapper">
                                    {checkPerm(Resources.CourseTeacher, CREATE_PERM) && (
                                        <button className="btn btn-primary margin-top-20"
                                                onClick={() => this.toggleAddTeacherModal(null)}
                                        >{translate("btn.add_new_teacher")}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="sticky-table">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            {translate("field.first_name")}
                                        </th>
                                        <th>
                                            {translate("field.last_name")}
                                        </th>
                                        <th>
                                            {translate("field.email")}
                                        </th>
                                        {checkPerm(Resources.CourseTeacher, UPDATE_PERM) && (
                                            <th>{translate("table.actions")}</th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {teachers}
                                    </tbody>
                                </table>

                                {!resource.isLoading && (teachers.length === 0) && (
                                    <NoResults text={translate("text.no_result")}/>
                                )}
                            </div>
                            <AddResourceDialog
                                cancel={this.toggleAddTeacherModal}
                                value={null}
                                visible={this.state.addTeacherModal}
                                keyid={"TeacherInCourseID"}
                                addClass={"invite-user-dialog"}
                                fields={{
                                    UserID: new Field('UserID', '', ['empty'], false, 'user')
                                }}
                                resourceName={Resources.CourseTeacher}
                                piggyResourceName={Resources.Course}
                                {...this.props}
                                id={this.getId()}
                            />
                        </React.Fragment>
                    )}

                    {!resource.isLoading && (this.state.activeTab === 5) && (
                        <React.Fragment>
                            <div className="page-reports-actions">
                                <div className="page-search-wrapper">
                                    {checkPerm(Resources.CoursesMessages, CREATE_PERM) && (
                                        <button className="btn btn-primary margin-top-20"
                                                onClick={() => this.toggleAddUserModal(null)}
                                        >{translate("btn.invite_new_user")}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="sticky-table">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            {translate("field.first_name")}
                                        </th>
                                        <th>
                                            {translate("field.last_name")}
                                        </th>
                                        <th>
                                            {translate("field.email")}
                                        </th>
                                        <th>
                                            {translate("field.CreateUpdateDate")}
                                        </th>
                                        <th>
                                            {translate("field.Completed")}
                                        </th>
                                        <th>
                                            {translate("field.EnrollState")}
                                        </th>
                                        {checkPerm(Resources.CourseUser, UPDATE_PERM) && (
                                            <th>{translate("table.actions")}</th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users}
                                    </tbody>
                                </table>

                                {!resource.isLoading && (users.length === 0) && (
                                    <NoResults text={translate("text.no_result")}/>
                                )}
                            </div>
                            <InviteUserDialog
                                cancel={this.toggleAddUserModal}
                                value={null}
                                visible={this.state.addUserModal}
                                keyid={"UserID"}
                                fields={{
                                    UserID: new Field('UserID', '', ['empty'], false, 'user')
                                }}
                                resourceName={Resources.CourseInvite}
                                piggyResourceName={Resources.Course}
                                {...this.props}
                                id={this.getId()}
                            />
                        </React.Fragment>
                    )}

                    {!resource.isLoading && (this.state.activeTab === 6) && (
                        <React.Fragment>
                            <div className="page-reports-actions">
                                <div className="page-search-wrapper">
                                    {checkPerm(Resources.CourseForum, CREATE_PERM) && (
                                        <button className="btn btn-primary margin-top-20"
                                                onClick={() => this.toggleAddForumModal(null)}
                                        >{translate("btn.add_new_thread")}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="sticky-table">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            {translate("table.forum_thread_name")}
                                        </th>
                                        <th>
                                            {translate("table.description")}
                                        </th>
                                        <th>
                                            {translate("table.create_update_date")}
                                        </th>
                                        <th>
                                            {translate("table.actions")}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {forumThreads}
                                    </tbody>
                                </table>

                                {!resource.isLoading && (users.length === 0) && (
                                    <NoResults text={translate("text.no_result")}/>
                                )}
                            </div>
                            <AddResourceDialog
                                cancel={this.toggleAddForumModal}
                                value={this.state.selectedForumItem}
                                visible={this.state.addForumModal}
                                keyid={"CourseForumThreadID"}
                                fields={this.state.forumFields}
                                resourceName={Resources.CourseForum}
                                piggyResourceName={Resources.Course}
                                {...this.props}
                                id={this.getId()}
                            />
                        </React.Fragment>
                    )}

                    {!resource.isLoading && (this.state.activeTab === 8) && (
                        <React.Fragment>
                            <div className="page-reports-actions">
                                <div className="page-search-wrapper">
                                    {checkPerm(Resources.CourseAdminCert, CREATE_PERM) && (
                                        <button className="btn btn-primary margin-top-20"
                                                onClick={() => this.toggleAddCertModal(null)}
                                        >{translate("btn.add_new_cert")}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="sticky-table">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>
                                            {translate("field.name")}
                                        </th>
                                        {checkPerm(Resources.CourseAdminCert, UPDATE_PERM) && (
                                            <th>{translate("table.actions")}</th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {certs}
                                    </tbody>
                                </table>

                                {!resource.isLoading && (certs.length === 0) && (
                                    <NoResults text={translate("text.no_result")}/>
                                )}
                            </div>
                            <AddResourceDialog
                                cancel={this.toggleAddCertModal}
                                value={this.state.selectedCertItem}
                                visible={this.state.addCertModal}
                                keyid={"CertID"}
                                fields={this.state.certFields}
                                resourceName={Resources.CourseAdminCert}
                                piggyResourceName={Resources.Course}
                                {...this.props}
                                id={this.getId()}
                            />
                        </React.Fragment>
                    )}
                </div>

                <ConfirmDialog
                    text={this.props.translate("message.are_you_sure_approve_user")}
                    submit={this.state.approveModal}
                    cancel={this.toggleApproveResourceModal}
                    visible={this.state.approveModal}
                    confirmText={"btn.enroll_approve"}
                    confirmClass={"btn-success"}
                    {...this.props}
                />

                <ConfirmDialog
                    text={this.props.translate("message.are_you_sure_delete_resourceName")}
                    submit={this.state.deleteModal}
                    cancel={this.toggleDeleteResourceModal}
                    visible={this.state.deleteModal}
                    {...this.props}
                />
            </PrivateLayout>
        );
    }
}

export default connect(state => state)(CreateCourseView);
