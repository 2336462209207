export default class Resources {

    static User = "user";
    static Users = "users";
    static Faq = "faq";

    static AdminDashboard = "dashboard";
    static AdminStatistics = "statistics/users";
    static AdminStatisticsGraphics = "statistics/graphics/users";
    static AdminStatisticsGraphicsCompleted = "statistics/graphics/quizs";
    static AdminUserStatistics = "users/statistics";
    static Course = "course";
    static CoursesLectures = "courses/lectures";
    static CoursesMessages = "messages";
    static CourseCategories = "categories";
    static CoursePublicCategories = "courses/categories";
    static CourseInvite = "courses/invite";
    static CourseTeacher = "courses/teachers";
    static CourseUser = "courses/user";
    static CourseQuiz = "quiz";
    static CourseAdminCert = "courses/cert";
    static CourseImage = "courses/images";
    static CourseQuizResults = "quiz/results";
    static CourseQuizResultsDetails = "quiz/results/details";
    static CourseQuizSave = "quiz/save";
    static CourseQuizStart = "quiz/start";
    static CourseQuizEnd = "quiz/end";

    static CourseForum = "courses/forum";
    static CourseForumMessages = "courses/forum/messages";

    static Settings = "settings";

    static Front = "front";

    static CoursesLecturesPage = "courses/lectures/page";

    static CoursesList = "courses/list";
    static CoursesDetails = "courses/details";
    static CoursesDetailsLectures = "courses/details/lectures";
    static CoursesDetailsQuizes = "courses/details/quizes";
    static CoursesDetailsForum = "courses/details/forum";
    static CourseLectureDetails = "courses/lectures/details";
    static CourseLecture = "courses/lectures/page";
    static CourseEnroll = "courses/enroll";
    static CourseCert = "courses/cert/download";

    static Conferences = "conferences";
    static Rooms = "conferences/rooms";
    static Room = "conferences/room";
    static RoomChat = "conferences/room/chat";
    static AdminConferences = "admin/conferences";
    static AdminConferencesRooms = "admin/conferences/rooms";
    static AdminConferencesUsers = "admin/conferences/users";
    static AdminCourseStatistics = "courses/statistics";

    static AboutPage = "about";

    static Support = "support";
}
