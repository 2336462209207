export function getSecondResource(data) {
    return {
        type: 'GET_SECOND_RESOURCE',
        data: data
    };
}

export function doneGetSecondResource(data) {
    return {
        type: 'DONE_GET_SECOND_RESOURCE',
        data: data
    };
}

export function errorGetSecondResource(data) {
    return {
        type: 'ERROR_GET_SECOND_RESOURCE',
        data: data
    };
}

export function createSecondResource(data) {
    return {
        type: 'CREATE_SECOND_RESOURCE',
        data: data
    };
}

export function doneCreateSecondResource(data) {
    return {
        type: 'DONE_CREATE_SECOND_RESOURCE',
        data: data
    };
}

export function errorCreateSecondResource(data) {
    return {
        type: 'ERROR_CREATE_SECOND_RESOURCE',
        data: data
    };
}

export function deleteSecondResource(data) {
    return {
        type: 'DELETE_SECOND_RESOURCE',
        data: data
    };
}

export function doneDeleteSecondResource(data) {
    return {
        type: 'DONE_DELETE_SECOND_RESOURCE',
        data: data
    };
}

export function errorDeleteSecondResource(data) {
    return {
        type: 'ERROR_DELETE_SECOND_RESOURCE',
        data: data
    };
}

export function resetSecondResource() {
    return {
        type: 'RESET_SECOND_RESOURCE',
        data: []
    }
}

export function updateSecondResource(data) {
    return {
        type: 'UPDATE_SECOND_RESOURCE',
        data: data
    };
}

export function doneUpdateSecondResource(data) {
    return {
        type: 'DONE_UPDATE_SECOND_RESOURCE',
        data: data
    };
}

export function errorUpdateSecondResource(data) {
    return {
        type: 'ERROR_UPDATE_SECOND_RESOURCE',
        data: data
    };
}
