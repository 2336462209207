import { checkUserHelper } from "../services/api-util";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import {
    doneCreateSecondResource,
    doneDeleteSecondResource,
    doneGetSecondResource,
    doneUpdateSecondResource,
    errorCreateSecondResource,
    errorDeleteSecondResource,
    errorUpdateSecondResource
} from "../actions/secondResource";
import { doneGetResource } from "../actions/resource";

export function* createSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result;
    result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
            }
        }
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateSecondResource(result.data));
            }
        } else {
            yield put(doneCreateSecondResource(result.data));
        }
    } else {
        yield put(errorCreateSecondResource(result.data));
    }
}

export function* watchCreateSecondResource() {
    yield takeLatest('CREATE_SECOND_RESOURCE', createSecondResourceCall);
}


export function* deleteResourceSecondContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
            }
        }

        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        } else {
            yield put(doneDeleteSecondResource(result.data));
        }
    } else {
        yield put(errorDeleteSecondResource(result.data));
    }
}

export function* watchDeleteSecondResource() {
    yield takeLatest('DELETE_SECOND_RESOURCE', deleteResourceSecondContactCall);
}


export function* updateSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
            }
        }
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneUpdateSecondResource(result.data));
            }
        } else {
            yield put(doneUpdateSecondResource(result.data));
        }
    } else {
        yield put(errorUpdateSecondResource(result.data));
    }
}

export function* watchUpdateSecondResource() {
    yield takeLatest('UPDATE_SECOND_RESOURCE', updateSecondResourceCall);
}
